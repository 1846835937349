import styled from "styled-components";

export const StyledBody = styled.div`
  font-family: "Montserrat";
`;

export const SBody = styled.div`
  font-family: Montserrat;
  font-size: 16px;
`;

export const SBody600 = styled(SBody)`
  font-weight: 600;
`;

export const SBody800 = styled(SBody)`
  font-weight: 800;
`;

export const SBodyBold = styled(SBody)`
  font-weight: bold;
`;

export const S15Body = styled.div`
  font-family: Montserrat;
  font-size: 15px;
`;

export const S15Body600 = styled(S15Body)`
  font-weight: 600;
`;

export const S15Body800 = styled(S15Body)`
  font-weight: 800;
`;

export const S15BodyBold = styled(S15Body)`
  font-weight: bold;
`;

export const Headline3 = styled(SBody)`
  font-weight: bold;
  font-size: 24px;
`;

export const SubTitle = styled(SBody)`
  font-size: 12px;
  opacity: 0.5;
  font-weight: 300;
`;

export const S11Body = styled(SBody)`
  font-size: 11px;
  font-weight: normal;
`;

export const S11Italic = styled(S11Body)`
  font-style: italic;
`;

export const S14Body = styled(SBody)`
  font-size: 14px;
  font-weight: 300;
`;

export const S14Body600 = styled(S14Body)`
  font-weight: 600;
`;

export const S14BodyBold = styled(S14Body)`
  font-weight: bold;
`;

export const S12Body = styled(SBody)`
  font-size: 12px;
  font-weight: 300;
`;

export const S12BodyBold = styled(S12Body)`
  font-weight: 600;
`;

export const S18Body = styled(SBody)`
  font-size: 18px;
`;

export const S18BodyBold = styled(S18Body)`
  font-weight: bold;
`;

export const S18Body600 = styled(S18Body)`
  font-weight: 600;
`;

export const S34Body = styled(SBody)`
  font-size: 34px;
`;

export const S34BodyBold = styled(S34Body)`
  font-weight: bold;
`;

export const S20Body = styled(SBody)`
  font-size: 20px;
`;

export const S20Body600 = styled(S20Body)`
  font-weight: 600;
`;

export const S20Body800 = styled(S20Body)`
  font-weight: 800;
`;

export const S20BodyBold = styled(S20Body)`
  font-weight: 600;
`;

export const SErrorDiv = styled(SBody)`
  text-align: right;
  font: italic normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ff5353;
  opacity: 1;
`;

export const S24Body = styled(SBody)`
  font-size: 24px;
`;

export const S24Body600 = styled(S24Body)`
  font-weight: 600;
`;

import { SVGProps } from ".";
const SettingsSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...p}
    >
      <g id="Group_328" data-name="Group 328" transform="translate(-310 -122)">
        <rect
          width="40"
          height="40"
          transform="translate(310 122)"
          fill="none"
        />
        <path
          d="M23.062,8.719h-.917a.9.9,0,0,1-.825-.559.941.941,0,0,1,.174-1.013l.649-.649a.943.943,0,0,0,0-1.326L18.828,1.857a.943.943,0,0,0-1.326,0l-.649.649a.94.94,0,0,1-1.01.175.9.9,0,0,1-.562-.826V.938A.937.937,0,0,0,14.344,0H9.656a.937.937,0,0,0-.937.937v.917a.9.9,0,0,1-.559.825.941.941,0,0,1-1.013-.174L6.5,1.857a.943.943,0,0,0-1.326,0L1.857,5.172a.937.937,0,0,0,0,1.326l.649.649a.935.935,0,0,1,.175,1.01.9.9,0,0,1-.826.562H.938A.937.937,0,0,0,0,9.656v4.687a.937.937,0,0,0,.937.937h.917a.932.932,0,0,1,.837.587.9.9,0,0,1-.186.985l-.649.649a.937.937,0,0,0,0,1.326l3.315,3.315a.943.943,0,0,0,1.326,0l.649-.649a.94.94,0,0,1,1.01-.175.9.9,0,0,1,.562.826v.917A.937.937,0,0,0,9.656,24h4.687a.937.937,0,0,0,.937-.938v-.917a.9.9,0,0,1,.559-.825.941.941,0,0,1,1.013.174l.649.649a.943.943,0,0,0,1.326,0l3.315-3.315a.943.943,0,0,0,0-1.326l-.649-.649a.9.9,0,0,1-.185-.986.932.932,0,0,1,.836-.586h.917A.937.937,0,0,0,24,14.344V9.656A.937.937,0,0,0,23.062,8.719Zm-.937,4.688a2.809,2.809,0,0,0-1.971,4.759l-1.989,1.989a2.794,2.794,0,0,0-3.037-.568,2.765,2.765,0,0,0-1.722,2.54H10.594a2.809,2.809,0,0,0-4.759-1.971L3.846,18.165a2.767,2.767,0,0,0,.577-3.016,2.8,2.8,0,0,0-2.548-1.743V10.594a2.764,2.764,0,0,0,2.539-1.72,2.8,2.8,0,0,0-.568-3.038L5.835,3.846a2.769,2.769,0,0,0,3.02.575,2.79,2.79,0,0,0,1.739-2.547h2.813a2.766,2.766,0,0,0,1.724,2.541,2.794,2.794,0,0,0,3.034-.57l1.989,1.989a2.8,2.8,0,0,0-.569,3.037,2.765,2.765,0,0,0,2.54,1.722v2.813ZM12,6.375A5.625,5.625,0,1,0,17.625,12,5.631,5.631,0,0,0,12,6.375Zm0,9.375A3.75,3.75,0,1,1,15.75,12,3.754,3.754,0,0,1,12,15.75Z"
          transform="translate(318 130)"
          fill={fill || "#fff"}
        />
      </g>
    </svg>
  );
};

export default SettingsSVG;

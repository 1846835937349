import useResizeObserver from "@react-hook/resize-observer";
import React from "react";
import { ISize } from "./types/types";

export function clickLink(link: string, name: string) {
  const a = document.createElement("a");
  a.href = link;
  a.download = `${name}.vcf`;
  document.body.appendChild(a);
  a.click();

  // Clean up the DOM
  document.body.removeChild(a);
}

export function copyToClipboard(t: string) {
  return navigator.clipboard.writeText(t);
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const tryForeverSessions: { [id: string]: unknown } = {};

export const tryForever = async (fn: () => Promise<any>, id?: string) => {
  if (!id) id = `${Date.now()}`;
  let i = 0;
  const session = Math.random();
  tryForeverSessions[id] = session;
  console.log("[tryForever] Start", id, session);
  let res = null;
  while (true) {
    if (tryForeverSessions[id] !== session) {
      console.log("[tryForever] Bail", id, session);
      break;
    }
    try {
      // eslint-disable-next-line no-await-in-loop
      res = await fn();
      break;
    } catch (error) {
      console.error("[tryForever] E", id, session, i, error);
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep((++i) ** 1.5 * 1000);
  }
  console.log("[tryForever] Finish", id, session);
  return res;
};

export const useSize = (target): ISize | undefined => {
  const [size, setSize] = React.useState();
  React.useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target, target?.current]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect as any));
  return size;
};

export function isThing(d: any) {
  return d !== undefined && d !== null;
}

export function shortenNumberString(n: number) {
  if (n < 1000) {
    return `${n}`;
  }
  if (n < 1000000) {
    return `${n / 1000}K`;
  }
  if (n < 1000000000) {
    return `${n / 1000000}M`;
  }
  return n.toString();
}

export const getTimeLeftObj = (timeLeftMS) => {
  if (timeLeftMS < 0) return null;
  const seconds = timeLeftMS / 1000;
  return {
    y: Math.floor(seconds / 31536000),
    d: Math.floor((seconds % 31536000) / 86400),
    h: Math.floor(((seconds % 31536000) % 86400) / 3600),
    m: Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
    s: Math.floor((((seconds % 31536000) % 86400) % 3600) % 60),
  };
};

export function numberInGroups(n: number | string, maxPt: number = 2) {
  const str = n?.toString();
  let [p1, p2] = str.split(".");
  p1 = p1.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;
  if (p2) {
    return [p1, p2.substring(0, maxPt)].join(".");
  }
  return p1;
}

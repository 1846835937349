import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";

import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S20Body, S20BodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

import animationData from "../../lottie/star-burst-v2.json";
import { ERewardTypes } from "../../types/types";

interface ClaimedDialogProps {
  style?: CSSProperties;
}

const ID = "ClaimedDialog";

const ClaimedDialog = observer((props: ClaimedDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.claimedDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.claimedDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.claimedDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            backdropFilter: "blur(10px)",
            overflow: "hidden",
            margin: "16px",
            height: 229,
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>Claimed!</S20BodyBold>
          </Flex>
          <VFlex
            className="self-stretch items-center justify-center flex-1"
            style={{
              backdropFilter: "blur(10px)",
              background: "#FFFFFF10",
            }}
          >
            <img style={{ height: 69, width: 69 }} src="/img/orb.png" />
            <Flex>
              <S20BodyBold>{`+${store.claimedDialogState?.value}`}</S20BodyBold>
              <S20Body>
                &nbsp;
                {REWARD_DATA[
                  store.claimedDialogState?.type || ERewardTypes.Energy
                ].title.toUpperCase()}
              </S20Body>
            </Flex>
          </VFlex>
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={() => {
            store.claimedDialogState = null;
          }}
          large
        >
          Done
        </MainButtonV2>
        <Lottie
          style={{
            position: "absolute",
            pointerEvents: "none",
            zIndex: 5,
          }}
          key={JSON.stringify(store.claimedDialogState)}
          height={store.screenSize.width * 0.8}
          width={store.screenSize.width * 0.8}
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </VFlex>
    </BaseDialog>
  );
});

export default ClaimedDialog;

import { SVGProps } from ".";
const LeaderboardSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      {...p}
    >
      <g transform="translate(-313.112 -182)">
        <path
          d="M14.572,2.124,13.6.246a.479.479,0,0,0-.84,0l-.972,1.877-2.154.295A.452.452,0,0,0,9.37,3.2l1.565,1.453L10.564,6.7a.477.477,0,0,0,.692.479L13.188,6.2l1.919.958A.476.476,0,0,0,15.8,6.68l-.371-2.046L16.994,3.2a.454.454,0,0,0-.264-.778l-2.154-.295Zm-4.028,7.93a1.287,1.287,0,0,0-1.318,1.257v7.541a1.287,1.287,0,0,0,1.318,1.257h5.272a1.287,1.287,0,0,0,1.318-1.257V11.311a1.287,1.287,0,0,0-1.318-1.257ZM1.318,12.568A1.287,1.287,0,0,0,0,13.825v5.028a1.287,1.287,0,0,0,1.318,1.257H6.59a1.287,1.287,0,0,0,1.318-1.257V13.825A1.287,1.287,0,0,0,6.59,12.568Zm17.134,3.771v2.514a1.287,1.287,0,0,0,1.318,1.257h5.272a1.287,1.287,0,0,0,1.318-1.257V16.338a1.287,1.287,0,0,0-1.318-1.257H19.769A1.287,1.287,0,0,0,18.451,16.338Z"
          transform="translate(315 188.003)"
          fill={fill || "#fff"}
        />
        <rect
          width="31"
          height="31"
          transform="translate(313.112 182)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default LeaderboardSVG;

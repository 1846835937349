import { observer } from "mobx-react";
import { CSSProperties } from "styled-components";
import { SCircularGradiantBox } from "../SCircularGradiantBox";
import { VFlex } from "../styled/Flex";
import DialogCloseBtnSVG from "../SVG/DialogCloseBtnSVG";
import BaseDialog, { DialogProps } from "./BaseDialog";
import { SDialogContainer } from "./SDialogContainer";

interface BaseDialogUIProps extends DialogProps {
  containerStyle?: CSSProperties;
  dialogContainerStyle?: CSSProperties;
  footer?: any;
}

const BaseDialogUI = observer((props: BaseDialogUIProps) => {
  const {
    dialogContainerStyle,
    footer,
    style,
    containerStyle,
    ...baseDialogProps
  } = props;
  return (
    <BaseDialog
      style={{
        padding: "8px 16px",
        gap: 8,
        ...style,
      }}
      {...baseDialogProps}
    >
      <DialogCloseBtnSVG
        className="simple-btn"
        onClick={() => {
          baseDialogProps.onClose && baseDialogProps.onClose();
        }}
        style={{
          alignSelf: "flex-end",
        }}
      />
      <SDialogContainer style={dialogContainerStyle}>
        <VFlex
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: 16,
            zIndex: -1,
          }}
        >
          <SCircularGradiantBox
            style={{
              transform: "scale(2, 5) translateY(-25%)",
              zIndex: -1,
            }}
          />
        </VFlex>
        <VFlex
          style={{
            padding: "8px 8px",
            alignSelf: "stretch",
            alignItems: "center",
            gap: 8,
            flex: 1,
            ...containerStyle,
          }}
        >
          {props.children}
        </VFlex>
      </SDialogContainer>
      {footer}
    </BaseDialog>
  );
});

export default BaseDialogUI;

import { SVGProps } from ".";
const MissionsSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g transform="translate(0 8)">
        <rect width="32" height="32" transform="translate(0 -8)" fill="none" />
        <g transform="translate(7.761 7.165)">
          <path
            d="M19.628,9.522a.822.822,0,0,0-.817-.822H13.088a.822.822,0,0,0,0,1.644h5.722A.822.822,0,0,0,19.628,9.522ZM12.271,16.1a.822.822,0,0,0,.817.822h5.722a.822.822,0,0,0,0-1.644H13.088A.822.822,0,0,0,12.271,16.1ZM10.2,6.752a.822.822,0,0,1,0,1.159L6.745,11.4a.821.821,0,0,1-1.161,0L3.851,9.653a.823.823,0,0,1,0-1.159A.812.812,0,0,1,5,8.494L6.164,9.662l2.894-2.91A.816.816,0,0,1,10.2,6.752Zm.008,6.584a.822.822,0,0,1,0,1.159L6.753,17.989a.821.821,0,0,1-1.161,0L3.851,16.229a.823.823,0,0,1,0-1.159A.812.812,0,0,1,5,15.07l1.161,1.167,2.894-2.91a.818.818,0,0,1,1.153.008Z"
            transform="translate(-3.464 -11.595)"
            fill={fill || "#fff"}
          />
          <g
            transform="translate(-3.76 -10.165)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-width="1.5"
          >
            <rect width="24" height="22" rx="4" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="22.5"
              height="20.5"
              rx="3.25"
              fill="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MissionsSVG;

import { SVGProps } from ".";
const ToolboxSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g id="Group_354" data-name="Group 354" transform="translate(0 8)">
        <rect
          id="Rectangle_585"
          data-name="Rectangle 585"
          width="32"
          height="32"
          transform="translate(0 -8)"
          fill="none"
        />
        <g
          id="Icon_ion-briefcase-outline"
          data-name="Icon ion-briefcase-outline"
          transform="translate(4.091 -2)"
        >
          <path
            id="Path_24"
            data-name="Path 24"
            d="M4.8,9H23.515a2.552,2.552,0,0,1,2.552,2.552V23.46a2.552,2.552,0,0,1-2.552,2.552H4.8A2.552,2.552,0,0,1,2.25,23.46V11.552A2.552,2.552,0,0,1,4.8,9Z"
            transform="translate(-2.25 -5.598)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M8.2,7.9V6.2a1.7,1.7,0,0,1,1.7-1.7h8.506a1.7,1.7,0,0,1,1.7,1.7V7.9m5.954,5.954H2.25m15.311,0v1.276a.425.425,0,0,1-.425.425H11.181a.425.425,0,0,1-.425-.425V13.857"
            transform="translate(-2.25 -4.5)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default ToolboxSVG;

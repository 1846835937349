import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { SCircularGradiantBox } from "./SCircularGradiantBox";
import { Flex } from "./styled/Flex";
import InboxSVG from "./SVG/InboxSVG";
import SettingsSVG from "./SVG/SettingsSVG";
import { SBody } from "./Typography";

interface HeaderProps {
  style?: CSSProperties;
}

const STrapezoidBox = styled.div`
  width: 232px;
  height: 64px;
  border: 1px solid #14212a;
  position: absolute;
  background-color: black;
  transform: perspective(10px) rotateX(-1deg);
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const Header = observer((props: HeaderProps) => {
  return (
    <Flex
      style={{
        justifyContent: "center",
      }}
    >
      <Flex
        style={{
          position: "relative",
          justifyContent: "space-between",
          padding: "4px 12px",
          border: "1px solid #14212A",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <SettingsSVG />
        <Flex
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <SCircularGradiantBox
            style={{
              width: "200vw",
              transform: "translateY(-100px)",
            }}
          />
        </Flex>
        <InboxSVG />
      </Flex>
      <STrapezoidBox>
        <SCircularGradiantBox
          style={{ width: "200%", transform: "translateY(-50px)" }}
        />
      </STrapezoidBox>
      <Flex
        style={{
          height: 64,
          position: "absolute",
          alignItems: "center",
          gap: 8,
        }}
      >
        <div
          style={{
            border: "1px solid #54DAF3",
            borderRadius: 120,
            width: 32,
            height: 32,
            overflow: "hidden",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src="/img/demo-avatar.png"
          />
        </div>
        <SBody
          style={{
            fontWeight: "bold",
            font: "normal normal 600 16px/19px",
          }}
        >
          Willy Sierra
        </SBody>
      </Flex>
    </Flex>
  );
});

export default Header;

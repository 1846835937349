import { observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { VFlex } from "./styled/Flex";

const SStylishContainer = styled(VFlex)`
  background: linear-gradient(146deg, #3c6784 0%, #254053 100%);
  border: 1px solid #5790b8;
  border-radius: 8px;
  opacity: 1;
  padding: 6px;
`;

export const SStylishInnerContainer = styled(VFlex)`
  background: linear-gradient(
    90deg,
    #1a2f3e 0%,
    #0f1b23 40%,
    #0f1b23 65%,
    #1a2f3e 100%
  );
  border-radius: 5px;
`;

interface StylishContainerProps extends React.PropsWithChildren {
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  outerComponent?: any;
}

const StylishContainer = observer((props: StylishContainerProps) => {
  const { style, innerStyle, outerComponent } = props;
  return (
    <SStylishContainer style={style}>
      <SStylishInnerContainer
        style={{
          alignItems: "center",
          padding: "6px 6px 6px 6px",
          flex: 1,
          ...innerStyle,
        }}
      >
        {props.children}
      </SStylishInnerContainer>
      {outerComponent}
    </SStylishContainer>
  );
});

export default StylishContainer;

import { GeneralAPIResBody } from "../types/api-types";
import { get, post } from "./api";

export const getCharacter = async () => {
  return (
    await get<GeneralAPIResBody>({
      uri: "/character",
    })
  ).body?.character;
};

export const claimDailyReward = async () => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/claim_daily_reward",
    })
  ).body?.character;
};

export const levelUp = async () => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/level_up",
    })
  ).body?.character;
};

export const levelUpItem = async (itemID: string) => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/level_up_item",
      body: { item_id: itemID },
    })
  ).body?.character;
};

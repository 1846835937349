import { observer } from "mobx-react-lite";
import { CSSProperties, FC, useEffect, useState } from "react";
import { TAB_BTN_STYLES } from "../constants";
import { MainButtonV2 } from "./MainButton";
import { Flex } from "./styled/Flex";
import { SBody600 } from "./Typography";

type Tab = {
  key?: any;
  label: any;
  style?: CSSProperties;
};

interface TabGroupProps {
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  tabs: Tab[];
  defaultTabKey?: string | number;
  onChangeTab: (newTab: Tab) => void;
  buttonWidth?: number;
  TextComponent?: FC;
  buttonStyle?: CSSProperties;
  activeButtonStyle?: CSSProperties;
}

const TabGroup = observer((props: TabGroupProps) => {
  const {
    tabs,
    defaultTabKey,
    onChangeTab,
    buttonWidth,
    buttonStyle,
    activeButtonStyle,
    style,
    contentStyle,
  } = props;

  const [currentTabKey, setCurrentTabKey] = useState(
    defaultTabKey ?? tabs[0].key ?? tabs[0].label,
  );

  useEffect(() => {
    defaultTabKey ?? setCurrentTabKey(defaultTabKey);
  }, [defaultTabKey]);

  return (
    <Flex style={{ gap: 9, alignSelf: "stretch", ...style }}>
      {tabs.map((tab) => {
        const key = tab.key ?? tab.label;
        return (
          <MainButtonV2
            key={key}
            style={{
              flex: buttonWidth ? "unset" : 1,
              width: buttonWidth ?? "unset",
              color: "white",
              transition: "all 0.3s ease",
              ...(currentTabKey === key
                ? TAB_BTN_STYLES.active
                : TAB_BTN_STYLES.inactive),
              ...tab.style,
            }}
            onClick={() => {
              setCurrentTabKey(key);
              onChangeTab(tab);
            }}
          >
            <SBody600 style={contentStyle}>{tab.label}</SBody600>
          </MainButtonV2>
        );
      })}
    </Flex>
  );
});

export default TabGroup;

import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { levelUp } from "../api/character";
import { authStore } from "../store/auth-store";
import { store } from "../store/store";
interface LoaderProps {}

const Loader = observer((props: LoaderProps) => {
  useEffect(() => {
    store.fetchGameConfig();
  }, []);
  useEffect(() => {
    return autorun(async () => {
      if (store.gameConfig && authStore.character) {
        if (
          authStore.character.exp >
          parseInt(
            store.gameConfig.character_table.level_up_exp_required[
              authStore.character.level
            ],
            10,
          )
        ) {
          const c = await levelUp();
          authStore.character = c;
        }
      }
    });
  }, []);
  return null;
});

export default Loader;

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { MainButton } from "../MainButton";
import { SCircularGradiantBox } from "../SCircularGradiantBox";
import { Flex, VFlex } from "../styled/Flex";
import { SStylishInnerContainer } from "../StylishContainer";
import LeftArrowSVG from "../SVG/LeftArrowSVG";
import TopRightTag from "../TopRightTag";
import { S18Body600, SBody, SBody600, SBodyBold } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface MagicCratesResultDialogProps {
  style?: CSSProperties;
}

const ID = "MagicCratesResultDialog";

const ArrowBtn = observer(
  (props: {
    onClick: () => void;
    isForward?: boolean;
    isDisabled?: boolean;
  }) => {
    const { isForward, isDisabled, onClick } = props;
    return (
      <VFlex
        style={{
          width: 48,
          alignItems: "center",
          justifyContent: "center",
          background: isDisabled
            ? "#243D50"
            : "linear-gradient(0deg, #375974 0%, #5583A7 100%)",
          pointerEvents: isDisabled ? "none" : "all",
        }}
        className={classNames({ "simple-btn": !isDisabled })}
        onClick={onClick}
      >
        <LeftArrowSVG
          style={{ transform: isForward ? "rotate(180deg)" : "" }}
          fill={isDisabled ? "#1b2b37" : "#fff"}
        />
      </VFlex>
    );
  },
);

const SResultItemContainer = styled(Flex)`
  position: relative;
  height: 105px;
  background: #ffffff20;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 6px;
`;

const SPriceTag = styled(Flex)`
  background: transparent linear-gradient(106deg, #5292bf 0%, #1c4360 100%);
  border: 1px solid #5ea5d6;
  border-radius: 8px;
  align-self: stretch;
  width: 106px;
  align-items: center;
`;

const MagicCratesResultDialog = observer(
  (props: MagicCratesResultDialogProps) => {
    return (
      <BaseDialogUI
        dialogID={ID}
        storeGetter={() => {
          return store.magicCratesResultDialogState;
          // return true;
        }}
        onShow={() => {}}
        onClose={() => {
          store.magicCratesResultDialogState = null;
        }}
        style={{
          padding: "8px 16px",
          gap: 8,
        }}
        containerStyle={{ flex: 1, padding: 0, gap: 0 }}
      >
        {(() => {
          if (!store.magicCratesResultDialogState) return null;
          return (
            <>
              <VFlex
                style={{
                  backgroundColor: "#1c3445",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderRadius: 12,
                  overflow: "hidden",
                  margin: 8,
                }}
              >
                <Flex
                  style={{
                    alignItems: "center",
                    height: 58,
                    gap: 6,
                  }}
                >
                  <img src="/img/magic-crate-icon.svg" />
                  <SBody style={{ fontWeight: "bold" }}>Magic Crates</SBody>
                </Flex>
                <Flex
                  style={{
                    backgroundColor: "#0C1C27",
                    alignSelf: "stretch",
                    alignItems: "stretch",
                    justifyContent: "center",
                    height: 42,
                    color: "#80B1D4",
                  }}
                >
                  <ArrowBtn
                    isDisabled={
                      store.magicCratesResultDialogState.roundNumber === 1
                    }
                    onClick={() => {
                      if (!store.magicCratesResultDialogState) return;
                      store.magicCratesResultDialogState.roundNumber -= 1;
                    }}
                  />
                  <S18Body600
                    style={{
                      flex: 1,
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >{`Round ${store.magicCratesResultDialogState.roundNumber}`}</S18Body600>
                  <ArrowBtn
                    isForward
                    onClick={() => {
                      if (!store.magicCratesResultDialogState) return;
                      store.magicCratesResultDialogState.roundNumber += 1;
                    }}
                    isDisabled={
                      store.magicCratesResultDialogState.roundNumber ===
                      store.magicCratesResultDialogState.maxNumber
                    }
                  />
                </Flex>
              </VFlex>
              <Flex className="h-[33px] overflow-hidden relative justify-center items-center self-stretch">
                <SCircularGradiantBox
                  centerColor="#060a0d"
                  outerColor="#20394b"
                  style={{ zIndex: -1 }}
                />
                {/* <S14Body className="text-[#5EDAFF]">
                  📣 Someone earned 0.1 TON! 📣
                </S14Body> */}
              </Flex>
              <VFlex className="m-2 gap-2 items-stretch self-stretch">
                {[
                  {
                    type: ERewardTypes.TON,
                    earned: 0.019,
                    v: 3,
                    playerCount: 1571,
                  },
                  {
                    type: ERewardTypes.WELL,
                    earned: null,
                    v: 10000,
                    playerCount: 676,
                  },
                  {
                    type: ERewardTypes.Energy,
                    earned: 5000,
                    v: 100000,
                    playerCount: 676,
                  },
                ].map(({ type, earned, v, playerCount }) => {
                  const rewardData = REWARD_DATA[type];
                  return (
                    <SResultItemContainer key={type}>
                      <SStylishInnerContainer
                        style={{
                          width: 94,
                          borderRadius: 8,
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <VFlex className="flex-1 items-center justify-center">
                          <img
                            className="w-[90%]"
                            src={REWARD_DATA[type].groupIcon}
                            alt=""
                          />
                        </VFlex>
                        <Flex
                          className="h-[25px] justify-center w-full"
                          style={{ background: rewardData.themeColor }}
                        >
                          <SBodyBold>{v}</SBodyBold>
                        </Flex>
                      </SStylishInnerContainer>
                      <VFlex style={{ flex: 1 }}>
                        <VFlex
                          style={{ maxWidth: 150, marginLeft: 12, flex: 1 }}
                          className="self-stretch"
                        >
                          <SBodyBold>1,571 Players</SBodyBold>
                          <SBody>bought this box</SBody>
                        </VFlex>
                        <Flex className="bg-[#405A6D] rounded-[8px] h-[40px] self-stretch items-center ml-2">
                          <SBodyBold className="ml-2 flex-1">
                            Claimed!
                          </SBodyBold>
                          <SPriceTag
                            style={{
                              margin: 3,
                            }}
                          >
                            <SBody600 className="flex-1 text-center">
                              0.019
                            </SBody600>
                            <img
                              style={{
                                width: 26,
                                height: 26,
                                marginRight: 6,
                              }}
                              src={rewardData.icon}
                            />
                          </SPriceTag>
                        </Flex>
                      </VFlex>
                      <VFlex
                        className="absolute top-0 right-0 items-center justify-center h-7 w-[94px]"
                        style={{
                          background: rewardData.themeColor,
                          borderRadius: "0px 8px",
                        }}
                      >
                        <SBodyBold>TON</SBodyBold>
                      </VFlex>
                      <TopRightTag
                        background={rewardData.themeColor}
                        text={rewardData.title.toUpperCase()}
                      />
                    </SResultItemContainer>
                  );
                })}
              </VFlex>
              <MainButton large className="self-stretch m-2">
                Claim All rewards
              </MainButton>
            </>
          );
        })()}
      </BaseDialogUI>
    );
  },
);

export default MagicCratesResultDialog;

import { SVGProps } from ".";
const CheckedSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...p}
    >
      <g transform="translate(-80 -337)">
        <circle
          cx="8"
          cy="8"
          r="8"
          transform="translate(80 337)"
          fill="#54daf3"
        />
        <path
          d="M6,11.913l2.913,2.913L13.767,9"
          transform="translate(78.379 333.087)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default CheckedSVG;

import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import HistorySVG from "../SVG/HistorySVG";
import { S20BodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

interface WithdrawalRequestedDialogProps {
  style?: CSSProperties;
}

const ID = "WithdrawalRequestedDialog";

const WithdrawalRequestedDialog = observer(
  (props: WithdrawalRequestedDialogProps) => {
    return (
      <BaseDialog
        dialogID={ID}
        storeGetter={() => {
          // return true;
          return store.isShowWithdrawalRequestedDialog;
        }}
        onShow={() => {}}
        onClose={() => {
          store.isShowWithdrawalRequestedDialog = null;
        }}
        style={{
          gap: 8,
          transition: "all 0.3s ease",
        }}
      >
        <VFlex
          style={{
            position: "relative",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <VFlex
            style={{
              border: "1px solid #376F9E",
              borderRadius: 16,
              backdropFilter: "blur(10px)",
              overflow: "hidden",
              margin: "16px",
              height: 229,
              background: "#0f182050",
            }}
          >
            <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
              <S20BodyBold>Withdrawal request</S20BodyBold>
            </Flex>
            <VFlex
              className="self-stretch items-center justify-center flex-1 text-center"
              style={{
                backdropFilter: "blur(10px)",
                background: "#FFFFFF10",
              }}
            >
              <img
                style={{ height: 58, width: 58 }}
                src={
                  REWARD_DATA[
                    store.isShowWithdrawalRequestedDialog
                      ? store.isShowWithdrawalRequestedDialog
                      : ERewardTypes.TON
                  ]?.icon
                }
              />
              <S20BodyBold>
                Your withdrawal request has been submitted
              </S20BodyBold>
            </VFlex>
          </VFlex>
          <MainBorderButtonV2
            large
            onClick={() => {
              store.isShowWithdrawalHistoryDialog = true;
            }}
            style={{
              margin: "0px 16px",
            }}
          >
            <HistorySVG className="w-7 h-7" fill="white" />
            History
          </MainBorderButtonV2>
          <MainButtonV2
            style={{
              margin: "16px 16px 0px 16px",
            }}
            onClick={() => {
              store.isShowWithdrawalRequestedDialog = null;
            }}
            large
          >
            Done
          </MainButtonV2>
        </VFlex>
      </BaseDialog>
    );
  },
);

export default WithdrawalRequestedDialog;

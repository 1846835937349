import { range } from "lodash";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { claimDailyReward } from "../../api/character";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { MainButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import CheckedSVG from "../SVG/CheckedSVG";
import { S12Body, SBody } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface DailyRewardDialogProps {
  style?: CSSProperties;
}

const ID = "DailyRewardDialog";

const STrapezoidBottomBox = styled(Flex)`
  width: 70%;
  height: 18px;
  position: absolute;
  background-color: #5292bf;
  transform: perspective(10px) rotateX(8deg);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
`;

const DayComponent = observer(
  (p: {
    day: number;
    isHighlighted: boolean;
    isChecked: boolean;
    value: number;
  }) => {
    const { day, isHighlighted, value, isChecked } = p;
    return (
      <VFlex
        style={{
          aspectRatio: "1/1",
          width: "100%",
          borderRadius: 6,
          padding: "2px 6px",
          alignItems: "center",
          background: isHighlighted
            ? "linear-gradient(100deg, #5292bf 0%, #2a5778 50%, #5292bf 100%)"
            : "linear-gradient(100deg, #1e3647 0%, #12202b 40%, #12202b 65%, #1e3647 100%)",
          position: "relative",
          border: isHighlighted ? "1px solid #54DAF3" : "none",
        }}
      >
        <Flex
          style={{
            justifyContent: "space-between",
            alignSelf: "stretch",
          }}
        >
          <S12Body>{`Day ${day}`}</S12Body>
          {isChecked && <CheckedSVG />}
        </Flex>
        <img
          style={{
            width: 40,
            height: 40,
          }}
          src="/img/orb.png"
        />
        <SBody className="font-bold z-10 bottom-[-4px] absolute">{value}</SBody>
        <STrapezoidBottomBox />
      </VFlex>
    );
  },
);

const DailyRewardDialog = observer((props: DailyRewardDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.isShowDailyRewardDialog;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.isShowDailyRewardDialog = false;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
    >
      <img
        style={{
          height: 66,
          width: 66,
        }}
        src="/img/daily-rewards.png"
      />
      <SBody
        style={{
          font: "normal normal bold 16px/19px Montserrat",
        }}
      >
        Daily Reward
      </SBody>
      <SBody
        style={{
          fontSize: 14,
          textAlign: "center",
        }}
      >
        Earn a daily energy bonus, simply check-in everyday. Keep the streak by
        not missing a day.
      </SBody>
      <div style={{}} className="grid grid-cols-4 gap-x-2 gap-y-2 w-full">
        {range(1, 13).map((n) => {
          return (
            <DayComponent
              key={n}
              day={n}
              isChecked={
                n <= (authStore.character?.continuous_checkin_days ?? 0)
              }
              isHighlighted={n === authStore.character?.continuous_checkin_days}
              value={500}
            />
          );
        })}
      </div>
      <MainButton
        style={{
          alignSelf: "stretch",
          marginTop: 12,
        }}
        onClick={async () => {
          store.isShowLoadingDialog = true;
          try {
            const c = await claimDailyReward();
            authStore.character = c;
            store.isShowLoadingDialog = false;
            store.claimedDialogState = {
              type: ERewardTypes.Energy,
              value: 500,
            };
          } catch (error) {
            store.isShowLoadingDialog = false;
          }
        }}
      >
        Claim
      </MainButton>
      <MainButton
        style={{
          background: "transparent",
          color: "white",
          fontWeight: 400,
        }}
        onClick={() => {
          store.isShowDailyRewardDialog = false;
        }}
      >
        Done
      </MainButton>
    </BaseDialogUI>
  );
});

export default DailyRewardDialog;

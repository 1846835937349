import { useTonConnectModal, useTonWallet } from "@tonconnect/ui-react";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import Header from "../Header";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import AirdropSVG from "../SVG/AirdropSVG";
import { S11Italic, S18BodyBold, S20BodyBold, SBodyBold } from "../Typography";
interface AirdropTabPageProps {
  style?: CSSProperties;
}

const SWithdrawItemContainer = styled(Flex)`
  background: #00000010;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 93px;
  padding: 0px 16px;
  align-items: center;
  gap: 14px;
`;

const AirdropTabPage = observer((props: AirdropTabPageProps) => {
  const { state, open, close } = useTonConnectModal();
  const wallet = useTonWallet();
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <Flex
        style={{
          padding: "20px 16px 14px 16px",
          background: "linear-gradient(0deg,#122532 0%, #15354c 100%)",
          borderBottom: "1px solid #81B3D6",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AirdropSVG />
        <S18BodyBold>Airdrop</S18BodyBold>
      </Flex>
      <VFlex className="relative flex-1">
        <img
          src="/img/airdrop-illustration.png"
          style={{ height: "100%", alignSelf: "center", position: "absolute" }}
        />
      </VFlex>
      <VFlex
        style={{
          alignItems: "stretch",
          alignSelf: "stretch",
          padding: "8px 16px",
          gap: 8,
        }}
      >
        {[
          {
            type: ERewardTypes.WELL,
            value: 100,
            min: 10,
          },
          {
            type: ERewardTypes.TON,
            value: 5,
            min: 5,
          },
        ].map(({ type, value, min }) => {
          const rewardData = REWARD_DATA[type];
          return (
            <SWithdrawItemContainer style={{}}>
              <img
                src={rewardData.icon}
                style={{
                  width: 46,
                  height: 46,
                }}
              />
              <VFlex className="flex-1">
                <SBodyBold>{`$${rewardData.title}`}</SBodyBold>
                <S20BodyBold style={{ color: rewardData.themeColor }}>
                  {value}
                </S20BodyBold>
              </VFlex>
              <VFlex
                style={{
                  width: 160,
                  gap: 8,
                }}
              >
                <MainButtonV2
                  large
                  onClick={() => {
                    store.openWithdrawDialog(type);
                  }}
                >
                  Withdraw
                </MainButtonV2>
                <S11Italic className="text-center text-[#A5A5A5]">
                  {`Minimum amount ${min} $${rewardData.title.toUpperCase()}`}
                </S11Italic>
              </VFlex>
            </SWithdrawItemContainer>
          );
        })}
      </VFlex>
      <Flex
        style={{
          // height: 84,
          alignItems: "center",
          padding: "12px 16px",
          position: "relative",
          backgroundColor: "#13181a",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <MainButtonV2
          style={{ flex: 1 }}
          onClick={() => {
            open();
          }}
        >
          Connect Wallet
        </MainButtonV2>
      </Flex>
      <img
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -10,
          opacity: 0.2,
          pointerEvents: "none",
        }}
        src="/img/squares.png"
      />
    </VFlex>
  );
});

export default AirdropTabPage;

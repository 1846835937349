import { observer } from "mobx-react-lite";
import { CSSProperties, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { playLuckyPick } from "../../api/lucky-pick";
import { REWARD_DATA } from "../../constants";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { numberInGroups, sleep, useSize } from "../../utils";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import StylishContainer from "../StylishContainer";
import SolidCircleQuestionMarkSVG from "../SVG/SolidCircleQuestionMarkSVG";
import TabGroup from "../TabGroup";
import {
  S12Body,
  S12BodyBold,
  S14Body,
  S14Body600,
  S14BodyBold,
  S15BodyBold,
  S18Body600,
  S18BodyBold,
  S20Body,
  S20BodyBold,
  S34BodyBold,
  SBody,
  SBody600,
  SBodyBold,
} from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface LuckyPickDialogProps {
  style?: CSSProperties;
}

const ID = "LuckyPickDialog";

const getIconByType = (type: "TON" | "WELL" | "Energy" | any) => {
  if (type === "TON") {
    return "/img/ton-coin.png";
  } else if (type === "WELL") {
    return "/img/well-coin.png";
  } else if (type === "Energy") {
    return "/img/orb.png";
  }
  return "";
};

const LuckyPickCard = observer(
  (props: {
    idx: number;
    value?: number;
    type?: "TON" | "WELL" | "Energy";
    hidden: boolean;
    onChoose: () => void;
  }) => {
    const { value, type, hidden, onChoose } = props;
    const [animationState, setAnimationState] = useState(0);
    useEffect(() => {
      (async () => {
        if (animationState === 0 && hidden) {
          setAnimationState(1);
          await sleep(300);
          setAnimationState(2);
        }
        if (animationState === 2 && !hidden) {
          setAnimationState(1);
          await sleep(300);
          setAnimationState(0);
        }
      })();
    }, [hidden]);
    const r = useRef();
    const size = useSize(r);
    let translateX = 0;
    let translateY = 0;
    if (size) {
      let fullWidth = size.width * 4 + 24;
      let fullHeight = size.height * 3 + 16;
      const toPointX = fullWidth / 2 - size.width / 2;
      const toPointY = fullHeight / 2 - size.height / 2;
      const row = Math.floor(props.idx / 4);
      const col = props.idx % 4;
      const x = col * size.width + Math.max(0, (col - 1) * 8);
      const y = row * size.height + Math.max(0, (row - 1) * 8);
      translateX = toPointX - x;
      translateY = toPointY - y;
    }
    return (
      <VFlex
        className="relative"
        onClick={() => {
          if (
            store.luckyPickDialogState &&
            store.luckyPickDialogState.state === "choosing"
          ) {
            onChoose && onChoose();
          }
        }}
        ref={r as any}
        style={{
          transform:
            store.luckyPickDialogState &&
            store.luckyPickDialogState.state === "choosing" &&
            animationState === 1
              ? `translate(${translateX}px, ${translateY}px)`
              : "translate(0px, 0px)",
          transition: "all 0.3s",
        }}
      >
        <img
          style={{
            width: "100%",
            position: "absolute",
            transform:
              animationState === 2 ? "rotateY(0deg)" : "rotateY(90deg)",
            transition: "all 0.3s",
          }}
          src="/img/well-card.png"
        />
        <VFlex
          style={{
            border: "1px solid #5790B8",
            borderRadius: 8,
            padding: 5,
            background: "linear-gradient(146deg, #3C6784 0%, #254053 100%)",
            width: "100%",
            height: ((size?.width || 0) * 110) / 78,
            aspectRatio: "78 / 110",
            transition: "all 0.3s",
            transform:
              animationState === 0 ? "rotateY(0deg)" : "rotateY(90deg)",
          }}
        >
          <VFlex
            style={{
              background:
                "linear-gradient(90deg, #1a2f3e 0%, #0f1b23 40%, #0f1b23 65%, #1a2f3e 100%)",
              borderRadius: 5,
              width: "100%",
              alignItems: "center",
              padding: "6px 6px 2px 6px",
            }}
          >
            <img style={{ width: 40, height: 40 }} src={getIconByType(type)} />
            <SBody
              style={{
                fontWeight: "bold",
                fontSize: type === "Energy" ? 14 : 16,
              }}
            >
              {type}
            </SBody>
          </VFlex>
          <SBody
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 1,
            }}
          >
            {/* {shortenNumberString(value || 0)} */}
            --
          </SBody>
        </VFlex>
      </VFlex>
    );
  },
);

const STopLeftInfoContainer = styled(Flex)`
  /* Layout Properties */
  position: absolute;
  top: 0px;
  right: 0px;
  width: 74px;
  height: 32px;
  /* UI Properties */
  background: linear-gradient(180deg, #375974 0%, #5583a7 100%);
  border-radius: 0px 8px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const LuckyPickDialog = observer((props: LuckyPickDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.luckyPickDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.luckyPickDialogState = false;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
      dialogContainerStyle={{ height: "80vh", overflow: "scroll" }}
      // footer={
      //   <Flex className="justify-center text-[#5EDAFF]">
      //     📣 Someone earned 0.1 TON! 📣
      //   </Flex>
      // }
    >
      {store.luckyPickDialogState !== false && (
        <>
          <Flex
            style={{
              gap: 16,
              height: 58,
              backgroundColor: "#0C1C2770",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: 12,
            }}
          >
            <img src="/img/lucky-pick-icon.svg" />
            <SBody style={{ fontWeight: "bold" }}>Lucky Pick</SBody>
          </Flex>
          {(() => {
            if (typeof store.luckyPickDialogState?.state === "object") {
              return null;
            }
            const banner = (
              <VFlex
                className="rounded-lg overflow-hidden relative simple-btn"
                onClick={() => {
                  if (typeof store.luckyPickDialogState === "object") {
                    store.luckyPickDialogState.isShowInfo = "jackpot";
                  }
                }}
              >
                <img src="/img/lucky-jackpot-v2.png" />
                <Flex className="bg-[#1B3243] h-[30px] justify-center items-center">
                  <S12BodyBold>
                    Prize pool:&nbsp;USD&nbsp;
                    <span className="text-[#FFCA77]">$500,000</span>&nbsp;in TON
                    & WELL
                  </S12BodyBold>
                </Flex>
                <STopLeftInfoContainer>
                  <S14Body600>Info</S14Body600>
                  <SolidCircleQuestionMarkSVG />
                </STopLeftInfoContainer>
              </VFlex>
            );
            if (typeof store.luckyPickDialogState?.isShowInfo === "string") {
              return (
                <>
                  <TabGroup
                    tabs={[
                      {
                        key: "jackpot",
                        label: "Magic jackpot",
                      },
                      {
                        key: "how",
                        label: "How to play",
                      },
                      {
                        key: "rewards",
                        label: "Rewards",
                      },
                    ]}
                    onChangeTab={(t) => {
                      store.luckyPickDialogState &&
                        (store.luckyPickDialogState.isShowInfo = t.key);
                    }}
                    contentStyle={{ fontSize: 11 }}
                    defaultTabKey={
                      store.luckyPickDialogState?.isShowInfo || "jackpot"
                    }
                  />
                  {(() => {
                    if (store.luckyPickDialogState?.isShowInfo === "jackpot") {
                      return (
                        <>
                          {banner}
                          <S18BodyBold>Magic Jackpot</S18BodyBold>
                          <S14Body
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Playing Lucky Pick gives you a shot at winning a
                            share of the Magic Jackpot prize pool. One of the
                            cards in the game contains one of the following
                            Jackpot prizes:
                          </S14Body>
                          <VFlex className="flex-1 gap-1">
                            {[
                              {
                                icon: REWARD_DATA.ton.icon,
                                v: store.luckyPickPools?.ton_pool.balance ?? 0,
                                name: "$TON",
                              },
                              {
                                icon: REWARD_DATA.well.icon,
                                v: store.luckyPickPools?.well_pool.balance ?? 0,
                                name: "$WELL",
                              },
                            ].map((r, i) => (
                              <Flex className="items-center gap-2" key={i}>
                                <img className="w-7 h-7" src={r.icon} />
                                <S20BodyBold>{numberInGroups(r.v)}</S20BodyBold>
                                <S20Body>{r.name}</S20Body>
                              </Flex>
                            ))}
                          </VFlex>
                        </>
                      );
                    }
                    if (store.luckyPickDialogState?.isShowInfo === "how") {
                      return (
                        <>
                          <img
                            className="w-full"
                            src="/img/lucky-pick-how-to.png"
                          />
                          <S18BodyBold>How to play Lucky Pick</S18BodyBold>
                          <S14Body
                            style={{
                              textAlign: "center",
                            }}
                          >
                            The game consists of 12 cards. You simply pick a
                            card to receive the prize it holds.
                          </S14Body>
                          <Flex className="gap-2">
                            {Object.entries(REWARD_DATA).map(([k, v]) => {
                              return (
                                <StylishContainer
                                  key={k}
                                  outerComponent={
                                    <SBody600 className="text-center">
                                      {v.title}
                                    </SBody600>
                                  }
                                >
                                  <img className="w-10 h-10" src={v.icon} />
                                </StylishContainer>
                              );
                            })}
                          </Flex>
                        </>
                      );
                    }
                    return (
                      <>
                        <S18BodyBold>Rewards list</S18BodyBold>
                        <VFlex
                          style={{
                            flex: 1,
                            borderRadius: 12,
                            backgroundColor: "#0C1C27",
                            alignSelf: "stretch",
                            alignItems: "stretch",
                            overflowY: "scroll",
                            padding: 10,
                            gap: 8,
                          }}
                        >
                          {[
                            {
                              title: "Magic Jackpot Reward",
                              background:
                                "linear-gradient(264deg, #F9EF8D 0%, #EFC463 36%, #BA7030 66%, #9C522D 100%)",
                              icon: "/img/lucky-jackpot-icon.png",
                              items: [],
                            },
                            {
                              title: "$WELL Rewards",
                              background: "#2977F8",
                              icon: REWARD_DATA[ERewardTypes.WELL].icon,
                              name: "$WELL",
                              items: ["100", "1,000", "10,000", "50,000"],
                            },
                            {
                              title: "$TON Rewards",
                              background: "#0395CD",
                              icon: REWARD_DATA[ERewardTypes.TON].icon,
                              items: [],
                            },
                          ].map(({ title, background, items, icon, name }) => {
                            return (
                              <VFlex className="gap-2" key={title}>
                                <Flex
                                  style={{
                                    background,
                                    height: 38,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    borderRadius: 8,
                                  }}
                                >
                                  <SBodyBold>{title}</SBodyBold>
                                </Flex>
                                <div className="gap-x-1 gap-y-1 grid grid-cols-2">
                                  {items.map((v, i) => {
                                    return (
                                      <Flex
                                        style={{ gap: 7 }}
                                        key={v}
                                        className="bg-[#081117] w-full p-2 rounded-lg"
                                      >
                                        <img className="w-5 h-5" src={icon} />
                                        <S14BodyBold>{v}</S14BodyBold>
                                        <S14Body>{name}</S14Body>
                                      </Flex>
                                    );
                                  })}
                                </div>
                              </VFlex>
                            );
                          })}
                        </VFlex>
                      </>
                    );
                  })()}
                  <MainBorderButtonV2
                    style={{ alignSelf: "stretch" }}
                    onClick={() => {
                      store.luckyPickDialogState &&
                        (store.luckyPickDialogState.isShowInfo = undefined);
                    }}
                  >
                    Done
                  </MainBorderButtonV2>
                </>
              );
            }
            if (store.luckyPickDialogState?.state === "initial") {
              return (
                <>
                  {banner}
                  <Flex className="self-stretch gap-2">
                    <img className="w-[40%]" src="/img/lucky-pick-info.png" />
                    <VFlex className="flex-1">
                      <S15BodyBold>Pick a card and win!</S15BodyBold>
                      <S14Body>
                        Each card hides a prize! When the game starts, the 12
                        cards will be shuffled. Pick a card to win—it's that
                        easy!
                      </S14Body>
                    </VFlex>
                  </Flex>
                  <Flex className="self-stretch gap-2">
                    <MainBorderButtonV2
                      className="flex-1"
                      onClick={() => {
                        store.luckyPickDialogState &&
                          (store.luckyPickDialogState.isShowInfo = "how");
                      }}
                    >
                      How to play?
                    </MainBorderButtonV2>
                    <MainBorderButtonV2
                      className="flex-1"
                      onClick={() => {
                        store.luckyPickDialogState &&
                          (store.luckyPickDialogState.isShowInfo = "rewards");
                      }}
                    >
                      Rewards
                    </MainBorderButtonV2>
                  </Flex>
                  {Object.entries(REWARD_DATA).map(([k, v]) => {
                    return (
                      <MainButtonV2
                        style={{
                          alignSelf: "stretch",
                        }}
                        onClick={() => {
                          if (store.luckyPickDialogState !== false) {
                            store.luckyPickDialogState.state = "choosing";
                            store.luckyPickDialogState.poolType =
                              k as ERewardTypes;
                          }
                        }}
                      >
                        Play
                        <img style={{ width: 30, height: 30 }} src={v.icon} />
                        <VFlex
                          style={{
                            alignItems: "flex-start",
                          }}
                        >
                          <S14Body style={{ fontWeight: "bold" }}>???</S14Body>
                          <S12Body style={{ marginTop: -10, fontWeight: 600 }}>
                            {v.title}
                          </S12Body>
                        </VFlex>
                      </MainButtonV2>
                    );
                  })}
                  <S14Body>
                    Daily chances limit:&nbsp;
                    <span style={{ fontWeight: 600 }}>100/100</span>
                  </S14Body>
                </>
              );
            }
            return (
              <>
                <S14Body style={{ textAlign: "center" }}>
                  Play Lucky Pick to win rewards in $TON, $WELL or Energy
                  points.
                </S14Body>
                <div
                  style={{
                    transition: "all 0.3s",
                  }}
                  className={"grid grid-cols-4 gap-x-2 gap-y-2 w-full"}
                >
                  {[
                    {
                      type: "TON",
                      value: 0.01,
                    },
                    {
                      type: "TON",
                      value: 0.1,
                    },
                    {
                      type: "TON",
                      value: 1,
                    },
                    {
                      type: "TON",
                      value: 5,
                    },
                    {
                      type: "WELL",
                      value: 10,
                    },
                    {
                      type: "WELL",
                      value: 100,
                    },
                    {
                      type: "WELL",
                      value: 1000,
                    },
                    {
                      type: "WELL",
                      value: 10000,
                    },
                    {
                      type: "Energy",
                      value: 100,
                    },
                    {
                      type: "Energy",
                      value: 1000,
                    },
                    {
                      type: "Energy",
                      value: 10000,
                    },
                    {
                      type: "Energy",
                      value: 100000,
                    },
                  ].map(({ type: t, value }, idx) => {
                    const type = t as "TON" | "WELL" | "Energy";
                    return (
                      <LuckyPickCard
                        type={type}
                        idx={idx}
                        value={value}
                        key={`${type}-${value}`}
                        hidden={
                          store.luckyPickDialogState &&
                          store.luckyPickDialogState.state !== "initial" &&
                          store.luckyPickDialogState.state !== idx
                        }
                        onChoose={async () => {
                          if (
                            typeof store.luckyPickDialogState === "object" &&
                            store.luckyPickDialogState.poolType
                          ) {
                            try {
                              store.isShowLoadingDialog = true;
                              const result = await playLuckyPick(
                                store.luckyPickDialogState.poolType,
                              );
                              authStore.character = result.body.character;
                              store.isShowLoadingDialog = false;
                              store.luckyPickDialogState.state = idx;
                              store.luckyPickDialogState.state = {
                                type: result.body.reward.currency,
                                value: result.body.reward.amount,
                              };
                            } catch (error) {
                              store.isShowDailyRewardDialog = false;
                            }
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </>
            );
          })()}
          {(() => {
            if (!store.luckyPickDialogState) return null;
            if (typeof store.luckyPickDialogState.state === "object") {
              return (
                <VFlex
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                    gap: 14,
                  }}
                >
                  <VFlex
                    style={{
                      alignItems: "center",
                      flex: 1,
                      alignSelf: "stretch",
                    }}
                  >
                    <S18Body600>Congratulations!</S18Body600>
                    <S18Body600>Here is your prize:</S18Body600>
                    <StylishContainer
                      style={{
                        width: 195,
                        height: 274,
                        marginTop: 25,
                      }}
                    >
                      <img
                        style={{ width: 92, height: 92, marginTop: 45 }}
                        src={getIconByType(
                          store.luckyPickDialogState.state.type,
                        )}
                      />
                      <S34BodyBold style={{ marginTop: 16 }}>
                        {store.luckyPickDialogState.state.value}
                      </S34BodyBold>
                      <S20BodyBold
                        style={{
                          marginTop: 0,
                        }}
                      >
                        {store.luckyPickDialogState.state.type}
                      </S20BodyBold>
                    </StylishContainer>
                    <div style={{ flex: 1 }} />
                    <MainButtonV2
                      style={{
                        height: 54,
                        alignSelf: "stretch",
                      }}
                      onClick={() => {
                        store.luckyPickDialogState = false;
                      }}
                    >
                      Done
                    </MainButtonV2>
                  </VFlex>
                </VFlex>
              );
            }
            return (
              store.luckyPickDialogState.state !== "initial" && (
                <VFlex
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                    gap: 14,
                  }}
                >
                  <SBody style={{ fontWeight: "bold", textAlign: "center" }}>
                    Choose by tapping the card you want to reveal
                  </SBody>
                </VFlex>
              )
            );
          })()}
        </>
      )}
    </BaseDialogUI>
  );
});

export default LuckyPickDialog;

import { SVGProps } from ".";
const LeftArrowSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.828"
      viewBox="0 0 20 20.828"
      {...p}
    >
      <g transform="translate(-8 -7.586)">
        <path
          d="M21,12l-9,9,9,9m9-9H12"
          transform="translate(-3 -3)"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default LeftArrowSVG;

import { SVGProps } from ".";
const InboxSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...p}
    >
      <g transform="translate(-310 -122)">
        <rect
          width="40"
          height="40"
          transform="translate(310 122)"
          fill="none"
        />
        <g transform="translate(315.961 127.169)">
          <path
            d="M3,8.208A2.208,2.208,0,0,1,5.208,6H22.871a2.208,2.208,0,0,1,2.208,2.208V21.455a2.208,2.208,0,0,1-2.208,2.208H5.208A2.208,2.208,0,0,1,3,21.455Zm0,2.208H25.079"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            d="M3,18h7.728a3.216,3.216,0,0,0,3.312,3.349A3.216,3.216,0,0,0,17.351,18h7.728"
            transform="translate(0 -3.191)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default InboxSVG;

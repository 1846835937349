import { observer } from "mobx-react-lite";
import { CSSProperties, useState } from "react";
import styled from "styled-components";
import { TabData } from "../constants";
import { store } from "../store/store";
import { ETabs } from "../types/types";
import { copyToClipboard, sleep } from "../utils";
import { MainBorderButtonV2, MainButton } from "./MainButton";
import { SCircularGradiantBox } from "./SCircularGradiantBox";
import { Flex, VFlex } from "./styled/Flex";
import CopySVG from "./SVG/CopySVG";
import { S12Body, SBody } from "./Typography";
interface BottomTabBarProps {
  style?: CSSProperties;
}

const SelectedBox = styled.div`
  background: radial-gradient(
    closest-side at 50% 50%,
    #00000010 0%,
    #5292bf10 100%
  );
  border: 1px solid #5292bf;
  border-radius: 8px;
  position: absolute;
  opacity: 1;
  left: 6px;
  right: 6px;
  height: 62px;
`;

const BottomTabBar = observer((props: BottomTabBarProps) => {
  const [isShowCopied, setIsShowCopied] = useState(false);
  return (
    <VFlex
      style={{
        width: "100%",
        maxWidth: "100%",
        height: store.currentTab === ETabs.FRIENDS ? 148 : 84,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        background: "#13181a",
        zIndex: 20,
      }}
    >
      {store.currentTab === ETabs.FRIENDS && (
        <Flex
          style={{
            // height: 84,
            alignItems: "center",
            padding: "0px 16px",
            gap: 10,
            flex: 1,
            position: "relative",
          }}
        >
          <MainButton
            style={{ height: 44, flex: 1 }}
            onClick={() => {
              store.mockFriends.push({
                name: `Mock Name${store.mockFriends.length}`,
                avatar: "/img/demo-avatar.png",
                value: 20000,
              });
            }}
          >
            Invite Friends
          </MainButton>
          <MainBorderButtonV2
            style={{ height: 44, width: 64 }}
            onClick={async () => {
              setIsShowCopied(true);
              copyToClipboard("referralcode");
              await sleep(1000);
              setIsShowCopied(false);
            }}
          >
            <CopySVG />
          </MainBorderButtonV2>
          <Flex
            style={{
              backgroundColor: "#000000",
              transform: "translateY(-60px)",
              pointerEvents: "none",
              transition: "all 0.3s",
              opacity: isShowCopied ? 1 : 0,
            }}
            className="absolute left-4 right-4 h-[38px] items-center justify-center rounded-lg"
          >
            <S12Body className="text-[#54DAF3]">
              Your referral code has been copied!
            </S12Body>
          </Flex>
        </Flex>
      )}
      <Flex style={{ flex: 1, minHeight: 84, height: 84 }}>
        <SCircularGradiantBox />
        {Object.entries(TabData).map(
          ([k, { icon: Icon, title, iconStyle }]) => {
            const tabKey = parseInt(k, 10) as ETabs.AIRDROP;
            const selected = tabKey === store.currentTab;
            const color = selected ? "#72E4EC" : "white";
            return (
              <VFlex
                style={{
                  transition: "all 0.3s",
                  gap: 6,
                  alignItems: "center",
                  alignSelf: "stretch",
                  justifyContent: "center",
                  flex: 1,
                  position: "relative",
                }}
                key={k}
                onClick={() => {
                  store.currentTab = tabKey;
                }}
              >
                {selected && <SelectedBox />}
                <VFlex
                  style={{
                    width: 24,
                    height: 24,
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    style={{
                      position: "absolute",
                      transition: "all 0.3s",
                    }}
                    fill={color}
                  />
                </VFlex>
                <SBody
                  style={{
                    transition: "all 0.3s",
                    font: "normal normal 600 10px/13px Montserrat",
                    color,
                    fontWeight: selected ? "bold" : "normal",
                  }}
                >
                  {title}
                </SBody>
              </VFlex>
            );
          },
        )}
      </Flex>
    </VFlex>
  );
});

export default BottomTabBar;

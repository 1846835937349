import { SVGProps } from ".";
const MinusSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      {...p}
    >
      <g transform="translate(-27 -514)">
        <path
          d="M25.5,19.5H6a1.5,1.5,0,0,1,0-3H25.5a1.5,1.5,0,0,1,0,3Z"
          transform="translate(33.25 518)"
          fill={fill || "#484848"}
        />
        <rect
          width="44"
          height="44"
          transform="translate(27 514)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default MinusSVG;

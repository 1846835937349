import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import HistorySVG from "../SVG/HistorySVG";
import MinusSVG from "../SVG/MinusSVG";
import PlusSVG from "../SVG/PlusSVG";
import { S14Body, S18Body600, SBody, SBody600, SBodyBold } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface WithdrawDialogProps {
  style?: CSSProperties;
}

const ID = "WithdrawDialog";

const WithdrawDialog = observer((props: WithdrawDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.withdrawDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.withdrawDialogState = null;
      }}
      style={{
        padding: "8px 16px",
      }}
      containerStyle={{ flex: 1, gap: 16 }}
    >
      {(() => {
        if (!store.withdrawDialogState) return null;
        const rewardData = REWARD_DATA[store.withdrawDialogState.type];
        return (
          <>
            <VFlex
              style={{
                backgroundColor: "#1c3445",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Flex
                style={{
                  alignItems: "center",
                  height: 58,
                  gap: 6,
                }}
              >
                <img src="/img/withdraw-icon.svg" />
                <SBody style={{ fontWeight: "bold" }}>Withdraw</SBody>
              </Flex>
            </VFlex>
            <SBody600 className="text-center">
              Withdraw your $WELL to your wallet
            </SBody600>
            <VFlex className="rounded-[12px] items-stretch self-stretch overflow-hidden">
              <VFlex className="h-[57px] bg-[#1E3344] items-center justify-center">
                <S14Body className="max-w-[192px] text-center">
                  Your $WELL will be sent to the following address:
                </S14Body>
              </VFlex>
              <VFlex className="h-[48px] bg-[#0C1C27] items-center justify-center">
                <S14Body
                  className="text-center max-w-[80%]"
                  style={{
                    whiteSpace: "stable",
                    wordBreak: "break-all",
                  }}
                >
                  0x2af317c549193a651AFb0513F6F687150E4ca67c
                </S14Body>
              </VFlex>
            </VFlex>
            <Flex
              className="self-stretch h-[50px] px-4"
              style={{
                backdropFilter: "blur(30px)",
                border: "1px solid #82B3D6",
                borderRadius: 9,
                alignItems: "center",
              }}
            >
              <SBodyBold className="flex-1">Your Balance</SBodyBold>
              <SBody600 className="mr-2">
                {`15.5K $${rewardData.title.toUpperCase()}`}
              </SBody600>
              <img className="w-6 h-6" src={rewardData.icon} />
            </Flex>
            <SBodyBold>Enter amount to withdraw</SBodyBold>
            <VFlex className="self-stretch">
              <Flex className="self-stretch gap-2">
                <VFlex
                  className="items-center justify-center w-11 h-11"
                  style={{
                    border: "2px solid #767676",
                    background:
                      "linear-gradient(0deg, #a1a1a1 0%, #d2d2d2 100%)",
                    borderRadius: 8,
                  }}
                >
                  <MinusSVG fill="#484848" />
                </VFlex>
                <Flex className="flex-1 gap-2 self-stretch rounded-lg bg-black p-1 pl-4 items-center">
                  <S18Body600 className="flex-1">10000</S18Body600>
                  <MainButtonV2
                    bgColorTop="#5481a5"
                    bgColorBottom="#3a5c78"
                    style={{ borderRadius: 6, color: "white" }}
                  >
                    MAX
                  </MainButtonV2>
                </Flex>
                <VFlex
                  className="items-center justify-center w-11 h-11"
                  style={{
                    border: "2px solid #84c9ff",
                    background:
                      "linear-gradient(0deg, #3a5c79 0%, #5380a3 100%)",
                    borderRadius: 8,
                  }}
                >
                  <PlusSVG fill="white" />
                </VFlex>
              </Flex>
              <S14Body className="text-center">
                Minimum amount XXX $WELL
              </S14Body>
              <S14Body className="text-[#54DAF3] text-center">
                Processing fee: XXX $TON
              </S14Body>
              <MainBorderButtonV2
                large
                style={{
                  borderColor: "#305e84",
                  background: "#1d3445",
                  alignSelf: "stretch",
                }}
                className="mt-2"
                onClick={() => {
                  store.isShowWithdrawalHistoryDialog = true;
                }}
              >
                <HistorySVG className="w-7 h-7" fill="white" />
                History
              </MainBorderButtonV2>
              <Flex className="self-stretch gap-2 mt-2">
                <MainBorderButtonV2
                  large
                  style={{
                    borderColor: "#305e84",
                    background: "#1d3445",
                    alignSelf: "stretch",
                    flex: 1,
                  }}
                >
                  Cancel
                </MainBorderButtonV2>
                <MainButtonV2
                  large
                  style={{
                    flex: 1,
                  }}
                  onClick={() => {
                    if (!store.withdrawDialogState) return;
                    const type = store.withdrawDialogState?.type;
                    store.withdrawDialogState = null;
                    store.isShowWithdrawalRequestedDialog = type;
                  }}
                >
                  Withdraw
                </MainButtonV2>
              </Flex>
            </VFlex>
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default WithdrawDialog;

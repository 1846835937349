import { makeAutoObservable } from "mobx";
import { setAccessToken } from "../api/api";
import { getCharacter } from "../api/character";
import { Character } from "../types/api-types";
import { store } from "./store";

class AuthStore {
  character: Character | null = null;

  get currentExp() {
    if (!this.character || !store.gameConfig) return 0;
    let exp = this.character.exp;
    let i = 0;
    while (
      exp >
      parseInt(store.gameConfig.character_table.level_up_exp_required[i], 10)
    ) {
      exp -= parseInt(
        store.gameConfig.character_table.level_up_exp_required[i],
        10,
      );
      i += 1;
    }
    return exp;
  }

  get nextExpRequired() {
    if (!this.character || !store.gameConfig) return 0;
    return parseInt(
      store.gameConfig.character_table.level_up_exp_required[
        this.character.level
      ],
      10,
    );
  }

  jwt: string | null = null;
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async signIn(tgUserId: string) {
    // this.loading = true;
    // this.error = null;

    // try {
    //   const response = await signIn({ tg_user_id: tgUserId });

    //   runInAction(() => {
    //     const { jwt, character } = response;
    //     this.jwt = jwt;
    //     this.character = character;
    //     setAccessToken(jwt); // Set the token globally for subsequent API requests
    //   });
    // } catch (error: any) {
    //   runInAction(() => {
    //     this.error = error.message || "Sign-in failed.";
    //   });
    //   // throw error; // Optionally re-throw for UI handling
    // } finally {
    //   runInAction(() => {
    //     this.loading = false;
    //   });
    // }
    this.jwt =
      "eyJhbGciOiJFUzM4NCJ9.eyJleHAiOjE3MzQxMjMxMzYuMCwiaXNzIjoid2VsbG5lc3MtY2xpY2tlciIsInRnX3VzZXJfaWQiOiJzdHJpbmcifQ.9eZ6LZyX5TyUwnHkzEWMvVh4dk1Hn-T_xeJeVPbNuezkrZH5CfZ2ORzFFKjDc0_zvEVVtFUG9-zIV9UjCFEvpViyiPa34BcNxJX4kWzSrLBsEWXvZOHvB_sABj1uQqXX";
    setAccessToken(this.jwt);
    const character = await getCharacter();
    this.character = character;
  }

  signOut() {
    this.character = null;
    this.jwt = null;
    setAccessToken(""); // Clear token globally
  }

  get isAuthenticated() {
    return !!this.jwt;
  }
}

export const authStore = new AuthStore();

import { SVGProps } from ".";
const PlusSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g id="Group_544" data-name="Group 544" transform="translate(-319 -514)">
        <path
          id="Icon_akar-plus"
          data-name="Icon akar-plus"
          d="M14.154,22.308V14.154m0,0V6m0,8.154h8.154m-8.154,0H6"
          transform="translate(326.846 521.846)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-width="3"
        />
        <rect
          id="Rectangle_723"
          data-name="Rectangle 723"
          width="44"
          height="44"
          transform="translate(319 514)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default PlusSVG;

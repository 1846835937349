import { SVGProps } from ".";
const TimerSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18.005"
      viewBox="0 0 18 18.005"
      {...p}
    >
      <path
        d="M12.94,21.942A9,9,0,0,1,6.692,6.458a.723.723,0,0,1,1,1.04,7.555,7.555,0,1,0,5.964-2.08V8.236a.725.725,0,1,1-1.449,0V4.662a.724.724,0,0,1,.725-.725,9,9,0,0,1,0,18Z"
        transform="translate(-3.938 -3.938)"
        fill={fill || "#fff"}
      />
      <path
        d="M12.037,11.344l4.921,3.536A1.489,1.489,0,0,1,15.227,17.3a1.438,1.438,0,0,1-.346-.346l-3.536-4.921a.5.5,0,0,1,.692-.692Z"
        transform="translate(-6.787 -6.787)"
        fill={fill || "#fff"}
      />
    </svg>
  );
};

export default TimerSVG;

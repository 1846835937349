import { observer } from "mobx-react";
import { useEffect } from "react";
import { authStore } from "../../store/auth-store";

const AuthLoader = observer(() => {
  useEffect(() => {
    const signInAsync = async () => {
      //TODO: Fix dummy string
      const res = await authStore.signIn("123");
      console.log("res " + JSON.stringify(res));
    };
    signInAsync();
  }, []);

  return <></>;
});

export default AuthLoader;

import { makeAutoObservable } from "mobx";
import { getGameConfig } from "../api/api";
import { listLuckyPickPools } from "../api/lucky-pick";
import { WIDTH_THRESHOLD } from "../constants";
import { GameConfig, LuckyPickPools } from "../types/api-types";
import { EMagicCrateTypes, ERewardTypes, ETabs } from "../types/types";
import { tryForever } from "../utils";

class Store {
  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };

  setScreenSize(s: { width: number; height: number }) {
    this.screenSize = s;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  currentTab: ETabs = ETabs.HOME;

  luckyPickPools: LuckyPickPools | null = null;

  clickTextDict: Record<
    string,
    {
      value: number;
      position: {
        left: number;
        top: number;
      };
    }
  > = {};

  isShowDailyRewardDialog = false;

  luckyPickDialogState:
    | false
    | {
        state:
          | "initial"
          | "choosing"
          | number
          | {
              type: ERewardTypes;
              value: number;
            };
        poolType?: ERewardTypes;
        isShowInfo?: "jackpot" | "how" | "rewards";
      } = false;

  isShowLoadingDialog = false;

  magicCratesDialogState: null | {
    isShowInfo: boolean;
  } = null;

  openMagicCratesDialog() {
    this.magicCratesDialogState = {
      isShowInfo: false,
    };
  }

  buyMagicCratesDialogState: null | {
    unitAmount: string;
    magicCrateType: EMagicCrateTypes;
  } = null;

  openBuyMagicCratesDialog(magicCrateType: EMagicCrateTypes) {
    this.buyMagicCratesDialogState = {
      unitAmount: "0",
      magicCrateType,
    };
  }

  buyMagicCratesResultDialogState: null | {
    unitAmount: number;
    magicCrateType: EMagicCrateTypes;
  } = null;

  magicCratesResultDialogState: null | {
    roundNumber: number;
    maxNumber: number;
  } = null;

  openMagicCratesResultDialog() {
    this.magicCratesResultDialogState = {
      roundNumber: 18,
      maxNumber: 18,
    };
  }

  claimedDialogState: null | {
    type: ERewardTypes;
    value: number;
  } = null;

  missionDialogState: null | {
    title: string;
    value: number;
    verifyFrom?: number;
    verified: boolean;
    isShowAttention: boolean;
    isClaimed: boolean;
  } = null;

  mockFriends: {
    name: string;
    avatar: string;
    value: number;
    claimed?: boolean;
  }[] = [];

  isShowWalletConnectedDialog = false;

  withdrawDialogState: {
    amount: string;
    type: ERewardTypes;
  } | null = null;

  openWithdrawDialog(type: ERewardTypes) {
    this.withdrawDialogState = {
      amount: "0",
      type,
    };
  }

  gameConfig: GameConfig | null = null;

  async fetchGameConfig() {
    await tryForever(async () => {
      const res = await getGameConfig();
      this.gameConfig = res.body.game_config;
      const res2 = await listLuckyPickPools();
      this.luckyPickPools = res2.body;
    }, "fetchGameConfig");
  }

  isShowWithdrawalRequestedDialog: null | ERewardTypes = null;

  isShowWithdrawalHistoryDialog = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new Store();

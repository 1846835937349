import initReactFastclick from "react-fastclick";

import { TonConnectUIProvider } from "@tonconnect/ui-react";
import classNames from "classnames";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { useEffect } from "react";

import "./App.css";
import AuthLoader from "./components/AuthLoader/AuthLoader";
import BottomTabBar from "./components/BottomTabBar";
import ClickPopupTextContainer from "./components/ClickPopupTextContainer";
import BuyMagicCratesDialog from "./components/Dialog/BuyMagicCratesDialog";
import BuyMagicCratesResultDialog from "./components/Dialog/BuyMagicCratesResultDialog";
import ClaimedDialog from "./components/Dialog/ClaimedDialog";
import DailyRewardDialog from "./components/Dialog/DailyRewardDialog";
import LoadingDialog from "./components/Dialog/LoadingDialog";
import LuckyPickDialog from "./components/Dialog/LuckyPickDialog";
import MagicCratesDialog from "./components/Dialog/MagicCratesDialog";
import MagicCratesResultDialog from "./components/Dialog/MagicCratesResultDialog";
import MissionDialog from "./components/Dialog/MissionDialog";
import WalletConnectedDialog from "./components/Dialog/WalletConnectedDialog";
import WithdrawalHistoryDialog from "./components/Dialog/WithdrawalHistoryDialog";
import WithdrawalRequestedDialog from "./components/Dialog/WithdrawalRequestedDialog";
import WithdrawDialog from "./components/Dialog/WithdrawDialog";
import Loader from "./components/Loader";
import { SCircularGradiantBox } from "./components/SCircularGradiantBox";
import { VFlex } from "./components/styled/Flex";
import GlobalCSS from "./components/styled/GlobalStyle";
import AirdropTabPage from "./components/tab-pages/AirdropTabPage";
import FriendsTabPage from "./components/tab-pages/FriendsTabPage";
import ItemsTabPage from "./components/tab-pages/ItemsTabPage";
import MainTabPage from "./components/tab-pages/MainTabPage";
import MissionsTabPage from "./components/tab-pages/MissionsTabPage";
import { store } from "./store/store";
import { ETabs } from "./types/types";

initReactFastclick();

configure({
  enforceActions: "never",
});

function App() {
  useEffect(() => {
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return (
    <TonConnectUIProvider manifestUrl="https://wellness-clicker-dev.pages.dev/tonconnect-manifest.json">
      {/* Your app */}
      <div
        className={classNames("flex flex-col items-stretch h-full relative")}
        style={{ overflow: "hidden", maxWidth: "100vw", maxHeight: "100vh" }}
      >
        <Loader />
        <AuthLoader />
        <GlobalCSS />
        <VFlex className="flex-1 z-10 overflow-y-hidden mb-[-12px]">
          {(() => {
            switch (store.currentTab) {
              case ETabs.HOME:
              case ETabs.UPGRADE:
                return <MainTabPage />;
              case ETabs.ITEMS:
                return <ItemsTabPage />;
              case ETabs.MISSIONS:
                return <MissionsTabPage />;
              case ETabs.FRIENDS:
                return <FriendsTabPage />;
              case ETabs.AIRDROP:
                return <AirdropTabPage />;
            }
          })()}
        </VFlex>
        <BottomTabBar />
        <ClickPopupTextContainer />
        <SCircularGradiantBox
          style={{
            transform: "scale(3, 4) translateY(-10%)",
            opacity: 0.5,
          }}
        />
      </div>
      <DailyRewardDialog />
      <LuckyPickDialog />
      <MagicCratesDialog />
      <MagicCratesResultDialog />
      <BuyMagicCratesDialog />
      <BuyMagicCratesResultDialog />
      <LoadingDialog />
      <MissionDialog />
      <ClaimedDialog />
      <WalletConnectedDialog />
      <WithdrawDialog />
      <WithdrawalHistoryDialog />
      <WithdrawalRequestedDialog />
    </TonConnectUIProvider>
  );
}

export default observer(App);

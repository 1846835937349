import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import Header from "../Header";
import { DiagonalGradiantButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import FriendsSVG from "../SVG/FriendsSVG";
import RegularCircleCheckSVG from "../SVG/RegularCircleCheckSVG";
import {
  S11Body,
  S12Body,
  S14Body600,
  S18BodyBold,
  SBody,
  SBody600,
  SBodyBold,
} from "../Typography";
interface FriendsTabPageProps {
  style?: CSSProperties;
}

const SFriendListItemContainer = styled(Flex)`
  align-self: stretch;
  height: 53px;
  background: #ffffff10;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  align-items: center;
  padding: 0px 11px 0px 9px;
`;

const FriendsTabPage = observer((props: FriendsTabPageProps) => {
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <Flex
        className="justify-between items-center mt-3 px-4 py-2"
        style={{
          borderBottom: "1px solid #81B3D6",
        }}
      >
        <Flex className="items-center gap-1">
          <FriendsSVG fill="#90bedf" />
          <S18BodyBold>Friends</S18BodyBold>
        </Flex>
        <VFlex className="items-end">
          <S12Body>Your referral code</S12Body>
          <SBody600>SQXQ-1234</SBody600>
        </VFlex>
      </Flex>
      <VFlex style={{ overflowY: "scroll" }}>
        <VFlex
          style={{
            margin: "8px 12px",
          }}
        >
          <S14Body600
            style={{
              alignSelf: "center",
            }}
          >
            Invite your friends
          </S14Body600>
          <SBody className="text-center">
            You and your friends will receive bonuses!
          </SBody>
          <Flex className="gap-2 mt-2 mb-1">
            <Flex
              style={{
                border: "1px solid #82B3D6",
                borderRadius: 8,
                flex: 1,
                height: 56,
                background: "#3d4f5d",
                alignItems: "center",
              }}
            >
              <img src="/img/orb.png" className="w-9 h-9" />
              <VFlex>
                <S11Body>Rewards for you:</S11Body>
                <SBody600 className="text-[#54DAF3]">+20,000</SBody600>
              </VFlex>
            </Flex>
            <Flex
              style={{
                border: "1px solid #82B3D6",
                borderRadius: 8,
                flex: 1,
                height: 56,
                background: "#3d4f5d",
                alignItems: "center",
              }}
            >
              <img src="/img/orb.png" className="w-9 h-9" />
              <VFlex>
                <S11Body>Rewards for friends:</S11Body>
                <SBody600 className="text-[#54DAF3]">+20,000</SBody600>
              </VFlex>
            </Flex>
          </Flex>
        </VFlex>
        <Flex
          style={{
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
          className="bg-black min-h-[57px] items-center px-4 justify-between"
        >
          <SBodyBold>Friends list</SBodyBold>
          <DiagonalGradiantButton
            style={{
              width: 172,
            }}
            disabled={
              store.mockFriends.every((friend) => friend.claimed) ||
              store.mockFriends.length === 0
            }
            onClick={() => {
              let v = 0;
              store.mockFriends.forEach((friend) => {
                if (!friend.claimed) {
                  v += friend.value;
                }
                friend.claimed = true;
              });
              store.claimedDialogState = {
                type: ERewardTypes.Energy,
                value: v,
              };
            }}
          >
            Claim All
          </DiagonalGradiantButton>
        </Flex>
        <VFlex className="items-center flex-1 pt-[14px] pb-[24px]">
          {store.mockFriends.length > 0 ? (
            <VFlex className="self-stretch flex-1 gap-2 px-4">
              {store.mockFriends.map((friend, index) => {
                return (
                  <SFriendListItemContainer
                    key={friend.name}
                    onClick={() => {
                      store.mockFriends[index].claimed = true;
                      store.claimedDialogState = {
                        type: ERewardTypes.Energy,
                        value: friend.value,
                      };
                    }}
                  >
                    <img
                      src={friend.avatar}
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 100,
                        border: "1px solid #54DAF3",
                        background: "#163A54",
                      }}
                    />
                    <S14Body600 className="ml-2 flex-1">
                      {friend.name}
                    </S14Body600>
                    {friend.claimed ? (
                      <Flex className="items-center gap-1">
                        <S14Body600>Claimed</S14Body600>
                        <RegularCircleCheckSVG />
                      </Flex>
                    ) : (
                      <>
                        <img className="w-7 h-7" src="/img/orb.png" />
                        <S14Body600>{`+${friend.value}`}</S14Body600>
                      </>
                    )}
                  </SFriendListItemContainer>
                );
              })}
            </VFlex>
          ) : (
            <>
              <FriendsSVG style={{ width: 46, height: 46 }} fill="#90bedf" />
              <S14Body600 className="text-center mt-2">
                No friends in your list yet,
              </S14Body600>
              <S14Body600 className="text-center">
                invite your friends and earn a bonus!
              </S14Body600>
            </>
          )}
        </VFlex>
      </VFlex>
    </VFlex>
  );
});

export default FriendsTabPage;

import styled from "styled-components";
import { MediaQueryMobileOnly } from "../../constants";

type Props = {};

export const ResponsiveFlex = styled.div`
  display: flex;
  align-items: stretch;

  ${MediaQueryMobileOnly} {
    flex-direction: column;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const VFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-contents: center;
  align-items: center;
`;

import { reaction } from "mobx";
import { observer } from "mobx-react";
import { CSSProperties, useEffect, useRef } from "react";
import { isThing } from "../../utils";
import { VFlex } from "../styled/Flex";

export interface DialogProps extends React.PropsWithChildren<any> {
  dialogID: string;
  onShow?: () => void;
  onClose?: () => void;
  storeGetter: () => any;
  style?: CSSProperties;
  isPure?: boolean;
}

const BaseDialog = observer((props: DialogProps) => {
  const { style, dialogID, onShow, onClose, storeGetter, children } = props;
  const dialogRef = useRef<HTMLDialogElement | undefined>();
  useEffect(() => {
    dialogRef.current = document.getElementById(dialogID) as HTMLDialogElement;
  }, [dialogID]);

  useEffect(() => {
    const cb = () => {
      const r = storeGetter();
      if (isThing(r) && r !== false) {
        onShow && onShow();
        dialogRef.current?.showModal();
      } else {
        onClose && onClose();
        dialogRef.current?.close();
      }
    };
    cb();
    const d = reaction(storeGetter, cb);
    return () => {
      dialogRef.current?.close();
      d();
    };
  }, []);
  const isShowing = storeGetter();
  return (
    <dialog
      id={dialogID}
      style={{
        opacity: isShowing ? 1 : 0,
        pointerEvents: isShowing ? "all" : "none",
      }}
    >
      <VFlex style={{ width: "100%", height: "100%", ...style }}>
        {children}
      </VFlex>
    </dialog>
  );
});

export default BaseDialog;

import { SVGProps } from ".";
const CopySVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.227"
      height="23.227"
      viewBox="0 0 23.227 23.227"
      {...p}
    >
      <g transform="translate(-2 -2)">
        <path
          d="M15.623,13.5h9.552A2.123,2.123,0,0,1,27.3,15.623v9.552A2.123,2.123,0,0,1,25.175,27.3H15.623A2.123,2.123,0,0,1,13.5,25.175V15.623A2.123,2.123,0,0,1,15.623,13.5Z"
          transform="translate(-3.07 -3.07)"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          d="M6.184,16.8H5.123A2.123,2.123,0,0,1,3,14.675V5.123A2.123,2.123,0,0,1,5.123,3h9.552A2.123,2.123,0,0,1,16.8,5.123V6.184"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default CopySVG;

import styled from "styled-components";
import { VFlex } from "../styled/Flex";

export const SDialogContainer = styled(VFlex)`
  background: #265e8330;
  border-radius: 16px;
  border: 1px solid #376f9e;
  height: 90vh;
  max-height: 578px;
  backdrop-filter: blur(90px);
  align-items: center;
  overflowy: scroll;
`;

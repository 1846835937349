import { LuckyPickAPIResBody, LuckyPickPools } from "../types/api-types";
import { ERewardTypes } from "../types/types";
import { get, post } from "./api";

export const playLuckyPick = async (currency: ERewardTypes) => {
  return post<LuckyPickAPIResBody>({
    uri: `/lucky_pick/play`,
    body: {
      currency,
    },
  });
};

export const listLuckyPickPools = async () => {
  return get<LuckyPickPools>({
    uri: `/lucky_pick/pools`,
  });
};

export const listLuckyPickRewardsHistory = async (
  currency: ERewardTypes,
  skip = 0,
  limit = 100,
) => {
  return get<{
    rewards: [
      {
        amount: 9007199254740991;
        character_id: "string";
        claimed_at: 9007199254740991;
        currency: "well";
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6";
        is_jackpot: true;
      },
    ];
  }>({
    uri: `/lucky_pick/rewards?skip=${skip}&limit=${limit}`,
    body: {
      currency,
    },
  });
};

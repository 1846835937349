import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { store } from "../../store/store";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S20BodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

interface WalletConnectedDialogProps {
  style?: CSSProperties;
}

const ID = "WalletConnectedDialog";

const WalletConnectedDialog = observer((props: WalletConnectedDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        // return true;
        return store.isShowWalletConnectedDialog;
      }}
      onShow={() => {}}
      onClose={() => {
        store.isShowWalletConnectedDialog = false;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            backdropFilter: "blur(10px)",
            overflow: "hidden",
            margin: "16px",
            height: 229,
            background: "#0f182050",
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>Success!</S20BodyBold>
          </Flex>
          <VFlex
            className="self-stretch items-center justify-center flex-1"
            style={{
              backdropFilter: "blur(10px)",
              background: "#FFFFFF10",
            }}
          >
            <img style={{ height: 69, width: 69 }} src="/img/wallet-icon.png" />
            <S20BodyBold>Your wallet is</S20BodyBold>
            <S20BodyBold>connected!</S20BodyBold>
          </VFlex>
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={() => {
            store.isShowWalletConnectedDialog = false;
          }}
          large
        >
          Done
        </MainButtonV2>
      </VFlex>
    </BaseDialog>
  );
});

export default WalletConnectedDialog;

import { SVGProps } from ".";
const StarSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g data-name="Group 353" transform="translate(-8 7)">
        <path
          data-name="Icon akar-star"
          d="M12.714,3.678a1.064,1.064,0,0,1,1.983,0l2.216,6.139a1.06,1.06,0,0,0,.991.678H23.35A1.042,1.042,0,0,1,24,12.36l-3.875,3.487a1.036,1.036,0,0,0-.345,1.169l1.415,6a1.055,1.055,0,0,1-1.614,1.2l-5.264-3.34a1.071,1.071,0,0,0-1.231,0l-5.264,3.34a1.055,1.055,0,0,1-1.614-1.2l1.415-6a1.036,1.036,0,0,0-.345-1.169L3.407,12.36a1.042,1.042,0,0,1,.653-1.866H9.505a1.059,1.059,0,0,0,.991-.678l2.216-6.139Z"
          transform="translate(10.001 -4.706)"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <rect
          data-name="Rectangle 584"
          width="32"
          height="32"
          transform="translate(8 -7)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default StarSVG;

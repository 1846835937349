import { SVGProps } from ".";
const HomeSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.264"
      height="23.246"
      viewBox="0 0 23.264 23.246"
      {...p}
    >
      <path
        d="M21.818,9.106,12.151,2.175a.908.908,0,0,0-1.037,0L1.446,9.106A.908.908,0,0,1,.388,7.63L10.059.7l.006,0a2.724,2.724,0,0,1,3.133,0l.006,0L22.876,7.63A.908.908,0,0,1,21.818,9.106Z"
        transform="translate(0 -0.196)"
        fill={fill || "#72e4ec"}
      />
      <path
        d="M57.094,118.9H51.828a.908.908,0,0,1-.908-.908v-3.632a.908.908,0,0,0-1.816,0v3.632a.908.908,0,0,1-.908.908H42.929a2.727,2.727,0,0,1-2.724-2.724v-8.145a2.727,2.727,0,0,1,1.151-2.224l7.08-5.111.009-.006a2.724,2.724,0,0,1,3.133,0l.009.006,7.08,5.111a2.727,2.727,0,0,1,1.151,2.224v8.145A2.727,2.727,0,0,1,57.094,118.9Zm-4.359-1.816h4.359a.909.909,0,0,0,.908-.908v-8.145a.909.909,0,0,0-.385-.743l-.009-.006-7.079-5.11a.909.909,0,0,0-1.036,0l-7.079,5.11-.009.006a.909.909,0,0,0-.385.743v8.145a.909.909,0,0,0,.908.908h4.359v-2.724a2.724,2.724,0,0,1,5.448,0Z"
        transform="translate(-38.38 -95.655)"
        fill={fill || "#72e4ec"}
      />
    </svg>
  );
};

export default HomeSVG;

import { observer } from "mobx-react-lite";
import { CSSProperties, useState } from "react";
import styled from "styled-components";
import { store } from "../../store/store";
import Header from "../Header";
import { Flex, VFlex } from "../styled/Flex";
import CheckedSVG from "../SVG/CheckedSVG";
import ChevaronRightSVG from "../SVG/ChevaronRightSVG";
import MissionsSVG from "../SVG/MissionsSVG";
import TabGroup from "../TabGroup";
import { S18BodyBold, S20Body600, SBody, SBody600 } from "../Typography";
interface MissionsTabPageProps {
  style?: CSSProperties;
}

const SMissionListItemContainer = styled(Flex)`
  position: relative;
  background: #ffffff10;
  border: 1px solid #82b3d6;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 74px;
  min-height: 74px;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
`;

const MissionListItem = observer(
  (props: {
    claimed: boolean;
    notify: boolean;
    value: number;
    title: string;
    thumbnail: string;
  }) => {
    const { notify, claimed, value, title, thumbnail } = props;
    return (
      <SMissionListItemContainer
        className="simple-btn"
        onClick={() => {
          if (claimed) return;
          store.missionDialogState = {
            title,
            value,
            verified: false,
            isShowAttention: false,
            isClaimed: false,
          };
        }}
      >
        <div
          className="w-[58px] h-[58px] rounded-lg ml-2"
          style={{ border: "1px solid #81B3D6", background: "#4b4b4b" }}
          // src={thumbnail}
        />
        <VFlex>
          <SBody>{title}</SBody>
          <Flex className="items-center gap-1">
            <img style={{ width: 32, height: 32 }} src="/img/orb.png" />
            <SBody600
              style={{
                color: claimed ? "#54DAF3" : "white",
              }}
            >{`+${value}`}</SBody600>
            <SBody
              style={{
                color: claimed ? "#54DAF3" : "white",
              }}
            >
              Claimed
            </SBody>
          </Flex>
        </VFlex>
        {!claimed && notify && (
          <div className="w-3 h-3 bg-[#54DAF3] rounded-full absolute top-3 right-3" />
        )}
        {!claimed && (
          <VFlex
            className="absolute bottom-0 right-0 justify-center items-center"
            style={{
              borderRadius: "8px 0px",
              width: 37,
              height: 32,
              background: "linear-gradient(0deg, #3C7FB4 0%, #6FC0FF 100%)",
            }}
          >
            <ChevaronRightSVG />
          </VFlex>
        )}
        {claimed && <CheckedSVG className="absolute right-2" />}
      </SMissionListItemContainer>
    );
  },
);

const MissionsTabPage = observer((props: MissionsTabPageProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <VFlex
        style={{
          padding: "20px 16px 14px 16px",
          background: "linear-gradient(0deg,#122532 0%, #15354c 100%)",
          borderBottom: "1px solid #81B3D6",
        }}
      >
        <Flex
          style={{
            alignItems: "center",
          }}
        >
          <MissionsSVG style={{ width: 44, height: 44 }} fill="#88b7d9" />
          <S18BodyBold style={{ marginLeft: 6 }}>Missions</S18BodyBold>
          <div style={{ flex: 1 }} />
          <img style={{ width: 46, height: 46 }} src="/img/orb.png" />
          <S20Body600 style={{ marginLeft: 6 }}>90,000</S20Body600>
        </Flex>
        <TabGroup
          tabs={[
            {
              key: 0,
              label: "WELL3 Missions",
            },
            {
              key: 1,
              label: "Partner Missions",
            },
          ]}
          onChangeTab={(t) => {
            setSelectedTab(t.key);
          }}
          defaultTabKey={0}
        />
      </VFlex>
      <VFlex
        className="gap-2 relative flex-1"
        style={{
          padding: "12px 16px",
          overflowY: "scroll",
          marginBottom: 12,
        }}
      >
        {[
          {
            title: "Join WELL3 TG channel",
            value: 5000,
            notify: false,
            claimed: false,
            thumbnail: "",
          },
          {
            title: "Join WELL3 on X",
            value: 5000,
            notify: true,
            claimed: false,
            thumbnail: "",
          },
          {
            title: "Join WELL3 TG channel",
            value: 5000,
            notify: true,
            claimed: true,
            thumbnail: "",
          },
          {
            title: "Join WELL3 TG channel2",
            value: 5000,
            notify: false,
            claimed: false,
            thumbnail: "",
          },
          {
            title: "Join WELL3 on X2",
            value: 5000,
            notify: true,
            claimed: false,
            thumbnail: "",
          },
          {
            title: "Join WELL3 TG channel2",
            value: 5000,
            claimed: true,
            notify: true,
            thumbnail: "",
          },
        ].map((p) => {
          return <MissionListItem key={p.title} {...p} />;
        })}
      </VFlex>
      <img
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -10,
          opacity: 0.2,
          pointerEvents: "none",
        }}
        src="/img/squares.png"
      />
    </VFlex>
  );
});

export default MissionsTabPage;

import { IS_PROD } from "../constants";
import { APIInput, APIResAsync } from "../types/types";

console.log({ IS_PROD });
// export const BASE_URL = IS_PROD ? "https://api.gm.io" : "https://api-t.gm.io";
// export const BASE_URL = IS_PROD ? "http://wellness-clicker-api-dev-432877398.ap-southeast-1.elb.amazonaws.com/api/v1" : "http://localhost:8081/api/v1";
export const BASE_URL =
  "http://wellness-clicker-api-dev-432877398.ap-southeast-1.elb.amazonaws.com/api/v1";

let accessToken: null | string = null;

export const setAccessToken = (token: string) => {
  accessToken = token;
};

export const getAccessToken = () => {
  return accessToken;
};

export async function api({
  uri,
  headers = {},
  body,
  method,
  isForm,
}: APIInput) {
  // // console.log(getFirebaseToken());
  // let authorization = await firebase.auth().currentUser.getIdToken();
  const combinedHeaders = Object.assign(headers, {});
  let inputBody = body;
  let finalUri = uri.startsWith("http") ? uri : BASE_URL + uri;
  // finalUri = `${finalUri}?mock_host=master.well-oauth-admin-panel.pages.dev`;

  if (!isForm) {
    inputBody = JSON.stringify(body);
    if (body != null && body instanceof Object) {
      inputBody = JSON.stringify(body);
    }
    combinedHeaders["Content-Type"] = "application/json";
    if (accessToken) {
      // combinedHeaders["oauth-token"] = accessToken;
      combinedHeaders["Authorization"] = `Bearer ${accessToken}`;
    }
  } else {
    // combinedHeaders['Content-Type'] = 'multipart/form-data';
  }
  // console.log('init fetch');
  // console.log(finalUri);
  // console.log(inputBody);
  // console.log(combinedHeaders);
  const res = await fetch(finalUri, {
    headers: combinedHeaders,
    body: inputBody,
    method,
  });
  let returnBody;
  // console.log(res);
  try {
    returnBody = await res.json();
    // console.log(returnBody);
    if (res.status > 200) {
      return Promise.reject(returnBody);
    }
  } catch (e) {
    if (res.status > 200) {
      return Promise.reject(e);
    }
  }
  return {
    status: res.status,
    body: returnBody,
  };
}

export function get<T>(obj: {
  uri: string;
  headers?: any;
  body?: any;
  method?: string;
}): APIResAsync<T> {
  obj.method = "GET";
  return api(obj as any);
}

export function post<T>(obj: {
  uri: string;
  headers?: any;
  body?: any;
  method?: string;
  isForm?: boolean;
}): APIResAsync<T> {
  obj.method = "POST";
  return api(obj as any);
}

export function DELETE<T>(obj: {
  uri: string;
  headers?: any;
  body?: any;
  method?: string;
  isForm?: boolean;
}): APIResAsync<T> {
  obj.method = "DELETE";
  // console.log('post');
  // console.log(obj);
  return api(obj as any);
}

export function getGameConfig(): APIResAsync<any> {
  return get({ uri: "/game_config" });
}

import { observer } from "mobx-react-lite";
import { CSSProperties, useState } from "react";
import { TAB_BTN_STYLES } from "../../constants";
import { authStore } from "../../store/auth-store";
import { numberInGroups } from "../../utils";
import Header from "../Header";
import { MainButton, MainButtonV2 } from "../MainButton";
import ProgressBar from "../ProgressBar";
import { Flex, VFlex } from "../styled/Flex";
import ChevaronRightSVG from "../SVG/ChevaronRightSVG";
import TimerSVG from "../SVG/TimerSVG";
import ToolboxSVG from "../SVG/ToolboxSVG";
import TopRightTag from "../TopRightTag";
import {
  S14Body,
  S14Body600,
  S14BodyBold,
  S18BodyBold,
  S20Body600,
  SBody600,
  SBodyBold,
} from "../Typography";
interface ItemsTabPageProps {
  style?: CSSProperties;
}

const ItemsTabPage = observer((props: ItemsTabPageProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const items = authStore.character?.items || [];
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <VFlex
        style={{
          padding: "20px 16px 14px 16px",
          background: "linear-gradient(0deg,#122532 0%, #15354c 100%)",
          borderBottom: "1px solid #81B3D6",
        }}
      >
        <Flex
          style={{
            alignItems: "center",
          }}
        >
          <ToolboxSVG style={{ width: 44, height: 44 }} fill="#88b7d9" />
          <S18BodyBold style={{ marginLeft: 6 }}>Items</S18BodyBold>
          <div style={{ flex: 1 }} />
          <img style={{ width: 46, height: 46 }} src="/img/orb.png" />
          <S20Body600 style={{ marginLeft: 6 }}>
            {numberInGroups(authStore.character?.energy ?? 0)}
          </S20Body600>
        </Flex>
      </VFlex>
      <VFlex className="flex-1 overflow-y-scroll">
        <VFlex
          style={{
            borderBottom: "1px solid #81B3D6",
          }}
        >
          <VFlex className="px-4 py-2 gap-2">
            <SBody600>Level up reward</SBody600>
            <Flex
              style={{
                position: "relative",
                height: 86,
                alignSelf: "stretch",
                alignItems: "center",
                padding: "0px 8px",
                gap: 8,
              }}
              className="diagonal-container"
            >
              <VFlex
                style={{
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Flex className="items-center gap-1">
                  <S14Body>{`LVL ${authStore.character?.level}`}</S14Body>
                  <ChevaronRightSVG fill="#54DAF3" />
                  <S14BodyBold
                    style={{ color: "#54DAF3" }}
                  >{`LVL ${(authStore.character?.level ?? 0) + 1}`}</S14BodyBold>
                </Flex>
                <ProgressBar
                  style={{
                    width: 140,
                  }}
                  percent={
                    (authStore.currentExp / authStore.nextExpRequired) * 100
                  }
                />
                <Flex>
                  <S14Body>
                    {numberInGroups(authStore.currentExp)} /&nbsp;
                  </S14Body>
                  <S14Body style={{ color: "#54DAF3" }}>
                    {numberInGroups(authStore.nextExpRequired)}
                  </S14Body>
                </Flex>
              </VFlex>
              <VFlex
                style={{
                  border: "1px solid #54DAF3",
                  borderRadius: 8,
                }}
              >
                <img style={{ height: 68 }} src="/img/egg1.png" />
              </VFlex>
              <TopRightTag
                style={{ width: 70 }}
                background={"#405A6D"}
                text="x1"
              />
              <MainButton
                style={{
                  alignSelf: "flex-end",
                  transform: "translateY(-6px)",
                }}
              >
                Open
              </MainButton>
            </Flex>
          </VFlex>
          <Flex className="gap-2 px-4 pb-4">
            {[
              {
                title: "Basic",
                img: "/img/egg1.png",
              },
              {
                title: "Rare",
                img: "/img/egg2.png",
              },
              {
                title: "Mythic",
                img: "/img/egg3.png",
              },
            ].map(({ title, img }) => {
              return (
                <VFlex className="diagonal-container flex-1 items-center px-[6px] gap-2 p-2">
                  <S14Body600>{title}</S14Body600>
                  <img
                    style={{
                      border: "1px solid #54DAF3",
                      borderRadius: 8,
                    }}
                    src={img}
                  />
                  <Flex className="gap-1">
                    <TimerSVG />
                    <S14Body600 style={{}}>00:00:00</S14Body600>
                  </Flex>
                  <MainButton
                    style={{
                      height: 28,
                      alignSelf: "stretch",
                      borderRadius: 4,
                    }}
                  >
                    Open
                  </MainButton>
                </VFlex>
              );
            })}
          </Flex>
        </VFlex>
        <Flex
          style={{
            borderBottom: "1px solid #81B3D6",
            padding: "10px 16px",
            gap: 8,
            overflowX: "scroll",
            overflowY: "hidden",
            minHeight: 62,
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "#071928",
          }}
        >
          {["All", "Yoga", "Running", "Swimming", "Jumping", "Dancing"].map(
            (w, idx) => {
              return (
                <MainButtonV2
                  key={w}
                  style={{
                    flex: 1,
                    color: "white",
                    transition: "all 0.3s ease",
                    ...(currentTab === idx
                      ? TAB_BTN_STYLES.active
                      : TAB_BTN_STYLES.inactive),
                  }}
                  onClick={() => {
                    setCurrentTab(idx);
                  }}
                >
                  <SBody600>{w}</SBody600>
                </MainButtonV2>
              );
            },
          )}
        </Flex>
        {items.length === 0 ? (
          <SBodyBold className="text-center self-center m-3">
            You don't have any item yet, go collect some!
          </SBodyBold>
        ) : (
          <div className="grid grid-cols-2 gap-2 p-4 pb-8">
            {items.map((i) => {
              return (
                <VFlex key={i.id} className="w-full items-stretch">
                  <Flex className="relative diagonal-container items-center p-2">
                    <TopRightTag background="#81B3D6" text="LVL 1" />
                    <div
                      style={{
                        border: "1px solid #707070",
                        backgroundColor: "#111111",
                        height: 84,
                        width: 84,
                        borderRadius: 8,
                      }}
                    />
                    <VFlex className="items-center flex-1 translate-y-3">
                      <img src="/img/orb.png" className="w-[34px] h-[34px]" />
                      <S14Body600>+600/h</S14Body600>
                    </VFlex>
                  </Flex>
                  <MainButtonV2
                    style={{
                      justifyContent: "space-between",
                      height: 32,
                      marginTop: 8,
                      borderRadius: 4,
                    }}
                  >
                    <S14BodyBold>Upgrade</S14BodyBold>
                    <Flex className="items-center gap-1">
                      <img
                        src="/img/orb.png"
                        style={{ width: 26, height: 26 }}
                      />
                      <S14BodyBold>500</S14BodyBold>
                    </Flex>
                  </MainButtonV2>
                </VFlex>
              );
            })}
          </div>
        )}
      </VFlex>
    </VFlex>
  );
});

export default ItemsTabPage;

import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";
import { store } from "../../store/store";
import BaseDialog from "./BaseDialog";
interface LoadingDialogProps {
  style?: CSSProperties;
}

import animationData from "../../lottie/loop-circular-dots.json";
import { authStore } from "../../store/auth-store";
import { VFlex } from "../styled/Flex";
import WellSVG from "../SVG/WellSVG";

const ID = "LoadingDialog";

const LoadingDialog = observer((props: LoadingDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.isShowLoadingDialog || !authStore.character;
      }}
      onShow={() => {}}
      onClose={() => {
        store.isShowLoadingDialog = false;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.isShowLoadingDialog ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          style={{
            pointerEvents: "none",
            zIndex: 5,
          }}
          height={store.screenSize.width * 0.8}
          width={store.screenSize.width * 0.8}
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
        <WellSVG
          style={{
            position: "absolute",
          }}
        />
      </VFlex>
    </BaseDialog>
  );
});

export default LoadingDialog;

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { CSSProperties, useCallback, useState } from "react";
import { Flex, VFlex } from "./styled/Flex";
import { SBody600 } from "./Typography";
export enum ColType {
  Sort,
  Filter,
  None,
}

export enum SortType {
  ASC,
  DESC,
}

export type SortingOption = [string, SortType];

type ConfigValue = {
  stylesThTr?: CSSProperties;
  type?: ColType;
  title: string;
  render: (data: any, configKey: string, configValue: ConfigValue) => any;
};

export interface BaseTableProps {
  style?: CSSProperties;
  sortingOption?: SortingOption;
  onSortingChange?: (sorting: SortingOption) => void;
  colConfig: Record<string, ConfigValue>;
  data: any[];
  keyGetter: (data: any, index?: number) => string;
  width?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  emptyContent?: React.ReactNode;
  loading?: boolean;
  pageNum?: number;
  skeletonHeight?: number;
  stickyWidth?: number;
  stickyCol?: string;
}

const BaseTable = observer((props: BaseTableProps) => {
  const {
    style,
    sortingOption,
    onSortingChange,
    colConfig,
    data,
    keyGetter,
    pageNum = 10,
    skeletonHeight = 40,
    emptyContent,
    loading,
    stickyWidth = 0,
    stickyCol,
  } = props;

  const [sorting, _setSorting] = useState<SortingOption | undefined>(
    sortingOption,
  );

  const setSorting = useCallback(
    (sorting: SortingOption) => {
      _setSorting(sorting);
      onSortingChange && onSortingChange(sorting);
    },
    [_setSorting, onSortingChange],
  );
  return (
    <VFlex
      style={{
        overflow: "auto",
        paddingBottom: 8,
        backgroundColor: "#000000",
        ...style,
      }}
    >
      <table>
        <thead className="mb-2" style={{ position: "sticky", top: 0 }}>
          <tr className="h-10 border-b border-divider01 bg-black">
            {Object.entries(colConfig).map(([key, value], index, arr) => {
              return (
                <th
                  key={key}
                  className={classNames({
                    "sticky z-10  bg-grey07": key === stickyCol,
                  })}
                  style={{
                    ...value.stylesThTr,
                    ...(key === stickyCol && {
                      left: `-${stickyWidth}px` || 0,
                    }),
                    position: "relative",
                  }}
                >
                  <VFlex style={{ width: "100%" }}>
                    <Flex
                      className="gap-2 justify-center items-center"
                      style={{}}
                    >
                      <SBody600
                        style={{
                          color: "white",
                          textAlign: "center",
                          borderRight:
                            arr.length - 1 === index
                              ? "none"
                              : "1px solid #6c96b3",
                          flex: 1,
                        }}
                      >
                        {value.title}
                      </SBody600>
                      {/* {value.type === ColType.Filter && (
                      <FilterSVG className="simple-btn" />
                    )}
                    {value.type === ColType.Sort && (
                      <SortSVG
                        className="simple-btn"
                        fillTop={
                          key === sorting?.[0] && sorting?.[1] === SortType.ASC
                            ? COLORS.primaryCheese
                            : undefined
                        }
                        fillBottom={
                          key === sorting?.[0] && sorting?.[1] === SortType.DESC
                            ? COLORS.primaryCheese
                            : undefined
                        }
                        onClick={() => {
                          if (key !== sorting?.[0]) {
                            setSorting([key, SortType.DESC]);
                          }
                          if (key === sorting?.[0]) {
                            setSorting([
                              key,
                              sorting?.[1] === SortType.DESC
                                ? SortType.ASC
                                : SortType.DESC,
                            ]);
                          }
                        }}
                      />
                    )} */}
                    </Flex>
                    <div
                      style={{
                        height: 1,
                        position: "absolute",
                        width: "101%",
                        backgroundColor: "#385163",
                        bottom: 0,
                      }}
                    />
                  </VFlex>
                </th>
              );
            })}
          </tr>
        </thead>
        {!loading ? (
          <tbody>
            {data?.map((dataItem) => {
              return (
                <tr
                  key={keyGetter(dataItem)}
                  // className={classNames(styles.TbodyTr)}
                >
                  {Object.entries(colConfig).map(([key, value], index) => {
                    return (
                      <td
                        key={key}
                        className={classNames({
                          "sticky z-10 left-0 ": key === stickyCol,
                        })}
                        style={{
                          ...value.stylesThTr,
                          ...(key === stickyCol && {
                            left: `-${stickyWidth}px` || 0,
                          }),
                        }}
                      >
                        <Flex
                          style={{ ...value.stylesThTr }}
                          className={classNames(
                            "py-2 px-1 items-start text-nowrap justify-center",
                          )}
                        >
                          {value.render(dataItem, key, value)}
                        </Flex>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            {[...new Array(pageNum)].map((_, i) => (
              <tr key={i} style={{ height: skeletonHeight }}>
                {Object.entries(colConfig).map(([key, col], idx) => (
                  <td key={idx}>
                    <Flex className="items-center justify-center px-1 py-2">
                      {key === "token" ? (
                        <Flex className="items-center justify-center">
                          <VFlex
                            style={{
                              gap: 4,
                              marginLeft: 12,
                              flex: 1,
                              overflow: "hidden",
                            }}
                          ></VFlex>
                        </Flex>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!data?.length && !loading && (
        <Flex className="items-center justify-center">{emptyContent}</Flex>
      )}
    </VFlex>
  );
});

export default BaseTable;

import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { MAGIC_CRATE_DATA, REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import {
  S18Body600,
  S18BodyBold,
  SBody,
  SBody600,
  SBodyBold,
  SErrorDiv,
} from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface BuyMagicCratesDialogProps {
  style?: CSSProperties;
}

const ID = "BuyMagicCratesDialog";

const SAmountBtnContainer = styled(Flex)`
  width: 74px;
  height: 40px;
  background: linear-gradient(0deg, #375974 0%, #5583a7 100%);
  border: 1px solid #84c9ff;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  align-items: center;
  justify-content: center;
`;

const SAmountNumberContainer = styled(Flex)`
  width: 124px;
  height: 40px;
  background: #1a2f40;
  border-radius: 8px;
`;

const SResultContainer = styled(VFlex)`
  background: #ffffff10;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 16px 24px;
  align-self: stretch;
`;

const BuyMagicCratesDialog = observer((props: BuyMagicCratesDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.buyMagicCratesDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.buyMagicCratesDialogState = null;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
      containerStyle={{ flex: 1 }}
    >
      {(() => {
        if (!store.buyMagicCratesDialogState) return null;
        const crateData =
          MAGIC_CRATE_DATA[store.buyMagicCratesDialogState.magicCrateType];
        return (
          <>
            <VFlex
              style={{
                backgroundColor: "#1c3445",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Flex
                style={{
                  alignItems: "center",
                  height: 58,
                  gap: 6,
                }}
              >
                <img src="/img/magic-crate-icon.svg" />
                <SBody style={{ fontWeight: "bold" }}>Buy Magic Crates</SBody>
              </Flex>
              <Flex
                style={{
                  backgroundColor: "#0C1C27",
                  alignSelf: "stretch",
                  alignItems: "center",
                  height: 140,
                  padding: 16,
                  gap: 24,
                }}
              >
                <img
                  style={{ height: "100%", width: "auto" }}
                  src={crateData.icon}
                />
                <VFlex>
                  <SBody600>{crateData.title}</SBody600>
                  <SBody600>Magic Crate</SBody600>
                  <Flex className="items-center gap-2 mt-1">
                    <img
                      src={
                        REWARD_DATA[
                          store.buyMagicCratesDialogState.magicCrateType
                        ].icon
                      }
                      className="w-7 h-7"
                    />
                    <SBody600 style={{}}>5K $WELL</SBody600>
                  </Flex>
                </VFlex>
              </Flex>
            </VFlex>
            <SBody600 className="mt-3">Units amount:</SBody600>
            <Flex
              style={{
                gap: 12,
              }}
            >
              <SAmountBtnContainer
                onClick={() => {
                  if (!store.buyMagicCratesDialogState) return;
                  store.buyMagicCratesDialogState.unitAmount = `${Math.max(parseInt(store.buyMagicCratesDialogState.unitAmount, 10) - 1, 1)}`;
                }}
              >
                -
              </SAmountBtnContainer>
              <SAmountNumberContainer>
                <input
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                  onChange={(evt) => {
                    if (!store.buyMagicCratesDialogState) return;
                    store.buyMagicCratesDialogState.unitAmount =
                      evt.target.value;
                  }}
                  value={store.buyMagicCratesDialogState.unitAmount}
                />
              </SAmountNumberContainer>
              <SAmountBtnContainer
                onClick={() => {
                  if (!store.buyMagicCratesDialogState) return;
                  store.buyMagicCratesDialogState.unitAmount = `${parseInt(store.buyMagicCratesDialogState.unitAmount, 10) + 1}`;
                }}
              >
                +
              </SAmountBtnContainer>
            </Flex>
            <Flex className="gap-2">
              <S18BodyBold>Total</S18BodyBold>
              <S18Body600 className="text-[#54DAF3]">10K $WELL</S18Body600>
              <img
                className="w-6 h-6"
                src={REWARD_DATA[ERewardTypes.WELL].icon}
              />
            </Flex>
            <SResultContainer>
              <Flex className="justify-between">
                <SBodyBold>Your Balance</SBodyBold>
                <Flex className="gap-2">
                  <SBody600>5K $WELL</SBody600>
                  <img
                    src={REWARD_DATA[ERewardTypes.WELL].icon}
                    className="w-6 h-6"
                  />
                </Flex>
              </Flex>
              <MainButtonV2
                style={{ background: "#279AF2", color: "white", marginTop: 16 }}
              >
                Top up $WELL
              </MainButtonV2>
            </SResultContainer>
            <MainButtonV2
              className="self-stretch mt-5"
              large
              onClick={() => {
                if (!store.buyMagicCratesDialogState) return;
                const amount = store.buyMagicCratesDialogState.unitAmount;
                store.buyMagicCratesResultDialogState = {
                  unitAmount: parseInt(amount, 10),
                  magicCrateType:
                    store.buyMagicCratesDialogState.magicCrateType,
                };
                store.buyMagicCratesDialogState = null;
              }}
            >
              Buy
            </MainButtonV2>
            <SErrorDiv className="mt-1">
              Insufficient $WELL balance, top up more.
            </SErrorDiv>
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default BuyMagicCratesDialog;

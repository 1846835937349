import { SVGProps } from ".";
const AirdropSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g id="Group_354" data-name="Group 354" transform="translate(0 8)">
        <rect width="32" height="32" transform="translate(0 -8)" fill="none" />
        <path
          d="M11.832-.75A5.814,5.814,0,0,1,16.4,1.739a10.346,10.346,0,0,1,1.994,6.3c0,.239-.008.486-.024.777l-.034.6H13.2v4.67h.852a2.859,2.859,0,0,1,.726.093L19.11,9.41h-.554l.038-.667c.015-.267.022-.491.022-.706a10.35,10.35,0,0,0-2.481-6.956l.678-1.02a11.63,11.63,0,0,1,7.3,7.694,1.273,1.273,0,0,1-.21,1.124,1.355,1.355,0,0,1-1.077.531L16.774,16.06a2.861,2.861,0,0,1,.138.875v4.449a2.859,2.859,0,0,1-2.855,2.855H9.608a2.859,2.859,0,0,1-2.855-2.855V16.935a2.861,2.861,0,0,1,.138-.875L.843,9.41A1.355,1.355,0,0,1-.234,8.879a1.274,1.274,0,0,1-.21-1.125A11.63,11.63,0,0,1,6.851.061l.678,1.02A10.35,10.35,0,0,0,5.048,8.037c0,.214.007.439.022.706l.038.667H4.554l4.327,4.763a2.859,2.859,0,0,1,.726-.093h.852V9.41H5.327l-.034-.6c-.016-.291-.024-.538-.024-.777a10.345,10.345,0,0,1,1.994-6.3A5.815,5.815,0,0,1,11.832-.75Zm5.3,8.9q0-.056,0-.111A9.082,9.082,0,0,0,15.4,2.508a4.587,4.587,0,0,0-3.569-2,4.588,4.588,0,0,0-3.569,2A9.081,9.081,0,0,0,6.531,8.037q0,.055,0,.111h5.19v7.194H9.608a1.583,1.583,0,0,0-.654.139l-.418.188L1.7,8.148H3.787q0-.056,0-.111A12.37,12.37,0,0,1,5.171,2.265,10.4,10.4,0,0,0,.77,8.1c0,.01,0,.012,0,.021a.086.086,0,0,0,.071.03H1.4l6.982,7.679-.2.388a1.6,1.6,0,0,0-.173.72v4.449a1.6,1.6,0,0,0,1.594,1.594h4.449a1.6,1.6,0,0,0,1.594-1.594V16.935a1.6,1.6,0,0,0-.173-.72l-.2-.388,6.982-7.679h.557a.086.086,0,0,0,.071-.03.016.016,0,0,0,0-.02,10.4,10.4,0,0,0-4.4-5.833,12.37,12.37,0,0,1,1.385,5.772q0,.055,0,.111h2.083l-6.832,7.521-.418-.188a1.583,1.583,0,0,0-.654-.139H11.943V8.148Z"
          transform="translate(4.168 -4.178)"
          fill={fill || "#fff"}
        />
      </g>
    </svg>
  );
};

export default AirdropSVG;
